import styled, { css, DefaultTheme } from 'styled-components';

import { IntermediateIcon } from 'assets';

import { opaque } from 'utils/colorTransform';

interface CheckboxProps {
  isInverse?: boolean;
  theme: DefaultTheme;
  width: string;
}

export const StyledIntermediateWrapper = styled.span<CheckboxProps>`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: ${(props) => props.width};
  justify-content: center;
  left: 0;
  margin-right: 0.5rem;
  margin-top: 0.1875rem;
  position: absolute;
  top: 0;
  width: ${(props) => props.width};
`;

export const StyledIntermediateIcon = styled(IntermediateIcon)`
  fill: none;

  rect:nth-child(2) {
    fill: ${(props) => props.theme.colors!.black};
  }
`;

export const StyledCheckbox = styled.input<CheckboxProps>`
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;

  &:focus + svg {
    box-shadow: 0 0 0 3px ${(props) => opaque(props.theme.colors!.greyMedium, 30)};
  }

  & + svg {
    box-sizing: border-box;
    width: ${(props) => props.width};
    height: ${(props) => props.width};
    cursor: pointer;
    border-radius: 0.125rem;
    border: 2px solid ${(props) => props.theme.colors!.greyDark};
    flex-shrink: 0;
    margin-right: 0.5rem;
    background-color: ${(props) => props.theme.colors!.greyLightest};
    margin-top: 0.1875rem;

    path {
      width: 70%;
      height: 70%;
      fill: none;
      stroke-width: 6px;
      stroke-dasharray: 100;
      stroke-dashoffset: 101;
    }
  }

  &:checked + svg {
    background-color: ${(props) => props.theme.colors!.greyDark};

    path {
      stroke-dashoffset: 0;
      stroke: ${(props) => props.theme.colors!.white};
      stroke-opacity: 1;
    }
  }

  &:required + svg {
    border-color: ${(props) => props.theme.colors.red};
  }

  &:focus(:not(:disabled)) + svg,
  &:hover(:not(:disabled)) + svg,
  &:focus:hover(:not(:disabled)) + svg {
    border-color: ${(props) => props.theme.colors!.greyDark};
  }

  &:disabled ~ * {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${(props) =>
    props.isInverse &&
    css`
      &:checked + svg {
        background-color: ${props.theme.colors!.white};

        path {
          stroke: ${props.theme.colors!.black};
        }
      }
    `}
`;

interface LabelProps {
  theme: DefaultTheme;
  styles?: object;
}

export const StyledLabel = styled.label<LabelProps>`
  display: flex;
  width: fit-content;
  font: ${(props) => props.theme.fonts.bodyMSemi};
  position: relative;
  text-align: left;

  ${(props) => ({ ...props.styles })}
`;

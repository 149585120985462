import { useEffect } from 'react';

export default function useHandleOutsideClick(ref, handleClose, isOpen, isCloseOutsideEnabled) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!isCloseOutsideEnabled) {
        return;
      }

      if (ref.current && !ref.current.contains(event.target) && isOpen) {
        handleClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, isOpen]);
}

import styled from 'styled-components';

interface CurrencyOptionAmountProps {
  leftOffset?: boolean;
}
interface CurrencyOptionBottomLineWrapperProps {
  highlightDetails?: boolean;
}

export const CurrencyOptionWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const CurrencyOptionOptionIcon = styled.div`
  box-sizing: border-box;
  height: 1.25rem;
  margin-right: 0.625rem;
  width: 1.25rem;
`;

export const CurrencyOptionColumn = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const CurrencyOptionCurrencyName = styled.div`
  margin-right: 0.3rem;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CurrencyOptionBottomLineWrapper = styled.div<CurrencyOptionBottomLineWrapperProps>`
  color: ${(props) => props.highlightDetails ? props.theme.colors!.red : props.theme.colors!.greyMedium};
  font: ${(props) => props.theme.fonts.bodyXsSemi};
  margin: 0.25rem 2rem 0 0;

  @media only screen and (min-width: 24rem) {
    margin: 0.25rem 1rem 0 0;
  }

  @media only screen and (min-width: 48rem) {
    font: ${(props) => props.theme.fonts.bodySSemi};
  }
`;

export const CurrencyOptionAccount = styled.span`
  margin: 0 0.5rem;
`;

export const CurrencyOptionAmount = styled.span<CurrencyOptionAmountProps>`
  margin-left: ${props => props.leftOffset ? '0.25rem' : 0};
`;

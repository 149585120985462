import * as React from 'react';


import { StyledScore } from './styles';
import { ScoreChangeEvent, ScoreComplexity } from './types';
import { DEFAULT_SCORE_COMPLEXITY, minScoreMap, scoreLabelsMap } from './constants';

interface Props {
  score: 0 | 1 | 2 | 3 | 4;
  scoreComplexity?: ScoreComplexity;
  onScoreChange?: (scoreEvent: ScoreChangeEvent) => void;
}

function Score(props: Props) {
  const { score, scoreComplexity, onScoreChange } = props;
  const chosenScoreComplexity = scoreComplexity || DEFAULT_SCORE_COMPLEXITY;

  const getScore = (): string => {
    if (!Number.isInteger(score)) return '';

    const minScore = minScoreMap[chosenScoreComplexity]
    const scoreDiff = score - minScore + 1;
    const chosenScoreMap = scoreLabelsMap[chosenScoreComplexity];
    const label = scoreDiff < 0 ? chosenScoreMap[0] : chosenScoreMap[scoreDiff];

    onScoreChange?.({
      score,
      minScore,
      label
    });

    return label;
  }

  return <StyledScore scoreComplexity={chosenScoreComplexity} score={score}>{getScore()}</StyledScore>;
}

export default Score;

import styled, { DefaultTheme } from 'styled-components';

import { ScoreComplexity } from './types';
import { scoreComplexityColors } from './constants';

interface ScoreProps {
  score: 0 | 1 | 2 | 3 | 4;
  scoreComplexity?: ScoreComplexity;
  theme: DefaultTheme;
}

const getColor = (props: ScoreProps): string => {
  const {
    score,
    scoreComplexity,
    theme: {
      colors,
    },
  } = props;

  return colors[scoreComplexityColors[scoreComplexity][score]];
};

export const StyledScore = styled.span<ScoreProps>`
  color: ${getColor};
  font: ${(props) => props.theme.fonts.bodyXs};
  line-height: 1.0625;
  padding-right: 1.125rem;
  font-weight: 400;
  display: block;
  text-align: right;
  position: absolute;
  right: 0;
  top: 0.375rem;
`;

import React, { ChangeEvent, useState } from 'react';

import {
  FROM_DATE,
  TO_DATE,
  CUSTOM,
  rangeOptions,
} from '../constants'
import { HeaderTypes } from '../types';
import { validateInputDate, getDateForSelectedRange } from '../utils';

import S from './styles';

const Header = ({
  fromInputValue,
  toInputValue,
  handleDateInputChange,
  isRange,
  displayCustomRanges,
  handleDateSelection,
  onDateInputFocus,
  error,
}: HeaderTypes) => {
  const [rangeList, setRangeList] = useState(getInitialList(CUSTOM));

  function getInitialList(limit: string) {
    return rangeOptions.map((element) => ({ ...element, checked: String(limit) === String(element.value) }));
  }

  const onChange = (dateType: string) => (e: ChangeEvent<HTMLInputElement>, dateInputType: string) => {
    const isValid = validateInputDate(e.target.value, dateInputType);
    if (isValid) {
      handleDateInputChange(e, dateType, dateInputType);
    }
  };

  const activeLabel = rangeList.find((element) => element.checked)?.label || 'Default label';

  function onElementChange(event) {
    const selectedId = event.target.id;
    const activeList = rangeOptions.map((element) => ({ ...element, checked: element.id === selectedId }));
    const range = getDateForSelectedRange(selectedId);
    handleDateSelection(range);

    setRangeList(activeList);
  }

  return isRange ? (
    <S.HeaderInput>
      {isRange && displayCustomRanges && (
        <S.CustomRangeDropdown
          openToLeft
          onChange={onElementChange}
          list={rangeList}
          name='calendar-date-range'
          label={activeLabel}
        />
      )}
      <S.HeaderContainer>
        <S.DateInputContainer
          values={fromInputValue}
          onChange={onChange(FROM_DATE)}
          error={error[FROM_DATE]}
          onDateInputFocus={() => onDateInputFocus(FROM_DATE)}
        />
        <S.DateRangeDivider>{' – '}</S.DateRangeDivider>
        <S.DateInputContainer
          values={toInputValue}
          onChange={onChange(TO_DATE)}
          error={error[TO_DATE]}
          onDateInputFocus={() => onDateInputFocus(TO_DATE)}
        />
      </S.HeaderContainer>
    </S.HeaderInput>
  ) : null;
};

export default Header;

import React, { ChangeEvent, useRef } from 'react';
import DropdownFilterControl from './dropdown-filter-control';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';

import S from './styles';
import { DropdownFilterCheckboxProps } from './types';

function DropdownFilterCheckbox({
  name,
  list = [],
  label: parentLabel,
  onChange,
  openToLeft = false,
  className,
}: DropdownFilterCheckboxProps) {
  const ref = useRef(null);

  function onChangeHandler(event: ChangeEvent) {
    onChange(event);
    ref.current.closeDropdown();
  }

  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  return (
    <ThemeProvider theme={theme}>
      <DropdownFilterControl ref={ref} label={parentLabel} openToLeft={openToLeft} className={className}>
        <S.DropdownWrapper>
          {list.map(({ checked, label, id, value }) => (
            <S.DropdownLabel aria-label={label} htmlFor={name} key={`filter-dropdown-${id}`}>
              <S.DropdownCheckbox
                checked={checked}
                id={id}
                name={name}
                onChange={onChangeHandler}
                type='checkbox'
                value={value}
              />
              <S.DropdownCheckboxWrapper>
                {label} {checked && <S.DropdownActiveIndicator />}
              </S.DropdownCheckboxWrapper>
            </S.DropdownLabel>
          ))}
        </S.DropdownWrapper>
      </DropdownFilterControl>
    </ThemeProvider>
  );
}

export default DropdownFilterCheckbox;

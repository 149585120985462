import styled, { DefaultTheme } from 'styled-components';
import { CopyIcon } from 'assets';
import { SuccessIcon } from 'assets';


import { HTMLProps } from 'react';

interface Props extends HTMLProps<HTMLInputElement> {
  theme: DefaultTheme;
  styles?: object;
}

export const StyledField = styled.div<Props>`
  color: ${(props) => props.theme.colors!.black};
  position: relative;
  font: ${(props) => props.theme.fonts.bodyL};
  text-align: left;

  ${(props) => ({ ...props.styles })}
`;

export const StyledLabel = styled.label<Props>`
  left: 0;
  position: absolute;
  top: 0.725rem;
  left: 1.125rem;
  font: ${(props) => props.theme.fonts.bodyS};
  color: ${(props) => props.theme.colors!.greyMedium};
`;

export const StyledContent = styled.p<Props>`
  box-sizing: border-box;
  border-width: 2px;
  border-style: solid;
  border-radius: 0.5rem;
  border-color: ${(props) => props.theme.colors!.greyLightest};
  color: inherit;
  font: inherit;
  padding: 2rem 2.75rem 0.75rem 1rem;
  background-color: ${(props) => props.theme.colors!.greyLightest};
  margin: 0;
`;

export const StyledButton = styled.button<Props>`
  background-color: transparent;
  border: none;
  top: 1.5875rem;
  cursor: pointer;
  outline: none;
  padding: 0.125rem;
  position: absolute;
  right: 1rem;
`;

export const StyledCopyIcon = styled(CopyIcon)`
  width: 1.5rem;
  fill: ${props => props.theme.colors.blue};
`

export const StyledSuccessIcon = styled(SuccessIcon)`
  width: 1.375rem;
`

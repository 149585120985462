import { ChangeEvent, FocusEventHandler } from 'react';
import { DateRange, DaySelectionMode } from 'react-day-picker';

import {
  FROM_DATE,
  TO_DATE,
} from './constants';

export interface CalendarProps {
  placeholder?: string;
  className?: string;
  customHandler?: React.ElementType<{}>;
  isPopup?: boolean;
  isRange?: boolean;
  value?: DateRange | Date | undefined;
  hasExternalInputFields?: boolean;
  onChange?: (date: DateRange | Date) => void;
  onClear?: () => void;
  applyOnSelect?: boolean;
  displayCustomRanges?: boolean;
  hideActionButtons?: boolean;
  inputError?: string;
}

export type InputDate = {
  day: string;
  month: string;
  year: string;
}

export type HandlerInputDates = {
  from?: InputDate;
  to?: InputDate;
}

export type HeaderTypes = {
  fromInputValue: InputDate;
  handleDateInputChange: (e: ChangeEvent<HTMLInputElement>, dateType: string, dateInputType: string) => void;
  toInputValue: InputDate;
  isRange?: boolean;
  displayCustomRanges?: boolean;
  handleDateSelection: (selection: DateRange | undefined) => void;
  error: {
    [FROM_DATE]: boolean;
    [TO_DATE]: boolean;
  };
  onDateInputFocus: (dateType: string) => () => void;
}

export type DateInputTypes = {
  className?: string;
  values: InputDate;
  label?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>, dateInputType: string) => void;
  onClick?: () => void;
  error: boolean;
  onDateInputFocus: () => FocusEventHandler<HTMLInputElement>;
}

export interface HandleTypes extends Omit<
  CalendarProps, 'className' | 'isPopup' | 'isRange' | 'isSingleView' | 'value'
> {
  selectedRange: DateRange,
  handlerRef: React.RefObject<HTMLDivElement>,
  isCalendarPopupOpen: boolean,
  fromInputValue: InputDate;
  toInputValue: InputDate;
  handleDateInputChange: (e: ChangeEvent<HTMLInputElement>, dateType: string, dateInputType: string) => void;
  isRange: boolean;
  popperRef: React.RefObject<HTMLDivElement>;
  onHandlerClick: () => void;
  selectedDateInputValue?: InputDate;
  onDateInputFocus: (dateType: string) => () => void;
  error: {
    [FROM_DATE]: boolean;
    [TO_DATE]: boolean;
  };
  inputError?: string;
  inputValues: HandlerInputDates;
}

export interface CalendarContentTypes extends Omit<
  CalendarProps, 'className' | 'isPopup' | 'isSingleView' | 'value'
> {
  fromInputValue?: InputDate;
  toInputValue?: InputDate;
  handleDateInputChange: (e: ChangeEvent<HTMLInputElement>, dateType: string, dateInputType: string) => void;
  onReset: () => void;
  hasExternalInputFields?: boolean;
  numberOfMonths: number;
  selectedRange: DateRange;
  selectedDateValue: Date;
  handleDateSelection: (selection: DateRange | undefined) => void;
  mode: DaySelectionMode;
  onApply?: () => void;
  applyOnSelect?: boolean;
  displayCustomRanges?: boolean;
  error: {
    [FROM_DATE]: boolean;
    [TO_DATE]: boolean;
  };
  onDateInputFocus: (dateType: string) => () => void;
  hideActionButtons?: boolean;
  className?: string;
}

export const Mode = {
  range: 'range',
  single: 'single',
};

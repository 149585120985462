import React, { Children, cloneElement, ReactElement, ReactNode, useRef } from 'react';
import { StyledTabs } from '../styles';
import isNil from 'lodash/isNil';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';

export interface TabsProps {
  children?: ReactNode;
  name: string;
  className?: string;
}

function Tabs({ children, name, className }: TabsProps) {
  if (!children) {
    return null;
  }
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  const parentReference = useRef<HTMLDivElement | null>(null);
  const tabsLength = Children.toArray(children).length;

  return (
    <ThemeProvider theme={theme}>
      <StyledTabs aria-label={name} ref={parentReference} role='tablist' className={className}>
        {Children
          .toArray(children)
          .filter((child: ReactElement) => !isNil(child))
          .map((child: ReactElement, tabPosition: number) => (
            <div role='presentation' key={tabPosition}>
              {cloneElement(child, { name, parentRef: parentReference, tabPosition, tabsLength })}
            </div>
          ))}
      </StyledTabs>
    </ThemeProvider>
  );
}

export default Tabs;

import styled from 'styled-components';

const SingleValueStyle = styled.input`
  background-color: transparent;
  border: none;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  outline: none;
  width: 90%;
`;

export default { SingleValueStyle };

import { MagnifyingGlassIcon, Close } from 'assets';
import { HTMLProps } from 'react';
import styled, { DefaultTheme } from 'styled-components';

interface Props extends HTMLProps<HTMLInputElement> {
    theme: DefaultTheme;
    styles?: object;
}

export const Wrapper = styled.label<Props>`
    width: 12.5rem;
    height: 2.5rem;
    position: relative;
    display: flex;
    align-items: center;
`;

export const SearchIcon = styled(MagnifyingGlassIcon)`
    position: absolute;
    top: 0.75rem;
    left: 0.625rem;
    width: 1rem;
    z-index: 1;

    path {
        fill: ${props => props.theme.colors.greyMedium};
    }
`;

export const SearchCloseIcon = styled(Close) <{ $isVisible: boolean }>`
    display: ${props => props.$isVisible ? 'block' : 'none'};
    fill: ${props => props.theme.colors.greyMediumDark};
    z-index: 1;
    position: absolute;
    right: 0.625rem;
    top: 0.8125rem;
    cursor: pointer;
    transition: ${props => props.theme.transition};

    &:hover {
        fill: ${props => props.theme.colors.greyMedium};
    }
`;

export const SearchInput = styled.input`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.colors.greyBright};
    border-radius: 0.5rem;
    padding: 0.5625rem 2.125rem;
    box-sizing: border-box;
    border: 0.0625rem solid ${props => props.theme.colors.greyBright};
    font: ${props => props.theme.fonts.bodyMSemi};
    color: ${props => props.theme.colors.black};
    transition: ${props => props.theme.transition};

    &:hover {
        border: 0.0625rem solid ${props => props.theme.colors.greyLight};
    }

    &:focus {
        border: 0.06255rem solid ${props => props.theme.colors.blue};
    }

    &::placeholder {
        position: absolute;
        font: ${props => props.theme.fonts.bodyM};
        color: ${props => props.theme.colors.greyMediumDark};
    }
`;

import React from 'react';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';
import PropTypes from 'prop-types';

import S from './styles';

export interface Props {
  label?: string;
  checked: boolean;
  onChange: () => void;
}

function Switch({ label, checked = false, onChange }: Props): JSX.Element {
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  return (
    <ThemeProvider theme={theme}>
    <S.Label label={label}>
      <span>{label}</span>
      <S.Input type='checkbox' checked={checked} onChange={onChange} />
      <S.Switch />
    </S.Label>
    </ThemeProvider>
  );
}

Switch.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Switch;

import styled from 'styled-components';

import { DoubleChevron } from 'assets';
import { ChevronLeft } from 'assets';
import { hex2rgba } from 'utils/style-helpers';

type IconProps = {
  $isDisabled: boolean;
  $isRotated?: boolean;
};

export const PaginationButton = styled.button`
  background: ${(props) => props.theme.colors!.white};
  border: none;
  cursor: pointer;
  height: 1.75rem;
  margin-right: 0.3125rem;
  width: 1.75rem;

  &[disabled] {
    cursor: not-allowed;
  }
`;

export const DoubleChevronIcon = styled(DoubleChevron) <IconProps>`
  path {
    fill: ${(props) => (props.$isDisabled ? props.theme.colors!.greyLight : props.theme.colors!.blue)};
  }

  transform: ${(props) => (props.$isRotated ? 'rotate(180deg)' : '')};
`;

export const SingleChevronIcon = styled(ChevronLeft) <IconProps>`
  path {
    fill: ${(props) => (props.$isDisabled ? props.theme.colors!.greyLight : props.theme.colors!.blue)};
  }

  transform: ${(props) => (props.$isRotated ? 'rotate(180deg)' : '')};
`;

export const PaginationWrapper = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: flex-start;
`;

export const NumberOfRows = styled.span`
  color: ${(props) => props.theme.colors!.greyMedium};
  font: ${(props) => props.theme.fonts.bodyS};
`;

export const RowsPerPageContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const RowsPerPageLabel = styled.span`
  display: none;

  @media only screen and (min-width: 48rem) {
    align-self: flex-start;
    color: ${(props) => props.theme.colors!.greyMedium};
    display: block;
    font: ${(props) => props.theme.fonts.bodyS};
    margin-right: 0.75rem;
  }
`;

export const PaginationContainer = styled.div<{ isSticky: boolean }>`
  align-items: baseline;
  background-color: ${(props) => props.theme.colors!.white};
  border-bottom: 0.0625rem solid ${(props) => hex2rgba(props.theme.colors!.black, 0.1)};
  display: flex;
  justify-content: space-between;
  padding: 1.4375rem 0 1.0625rem;
  position: ${(props) => (props.isSticky ? 'sticky' : 'relative')};
  top: 0;
  width: 100%;
`;

import styled from 'styled-components';
import { DayPicker } from 'react-day-picker';

import { hex2rgba } from 'utils/style-helpers';
import { ChevronLeft } from 'assets';
import UIKitButton from 'components/button';

import 'react-day-picker/dist/style.css';

type ContainerProps = {
  isRange: boolean;
  hasExternalInputFields: boolean;
};

type ChevronIconProps = {
  $isRotated?: boolean;
};

const Calendar = styled(DayPicker)`
  margin: 0;
  padding: 1.25rem;
  background: ${(props) => props.theme.colors.white};
`;

const Container = styled.div<ContainerProps>`
  width: 100%;
  font: ${(props) => props.theme.fonts.bodyM};
  box-shadow: ${({ theme: { colors: { greyDarkest } } }) =>
    `0 0.125rem 1.5rem ${hex2rgba(greyDarkest, 0.1)}, inset 0 0 0.0625rem ${hex2rgba(greyDarkest, 0.4)}`};
  border-radius: 0.375rem;
  overflow: hidden;

  .rdp-button {
    border: 0.0625rem solid ${(props) => props.theme.colors.greyBright};
    font: ${(props) => props.theme.fonts.bodyXsSemi};
    color: ${(props) => props.theme.colors.black};
    border-radius: 0;

    &:hover {
      border: 0.0625rem solid ${(props) => props.theme.colors.greyDarkest};
      background: transparent;
    }

    &.rdp-nav_button {
      border: none;

      &:hover {
        background: ${(props) => props.theme.colors.greyBright};
        border-radius: 50%;
      }
    }
  }

  .rdp-day_selected.rdp-day_range_middle {
    border: 0.0625rem solid ${(props) => props.theme.colors.greyBright};
    background-color: ${(props) => props.theme.colors.greyMedium};
    color: ${(props) => props.theme.colors.white};
    max-width: unset;

    &:hover {
      border: 0.0625rem solid ${(props) => props.theme.colors.black};
      background-color: ${(props) => props.theme.colors.greyMedium};
    }
  }

  .rdp-day_outside {
    border: none;
  }

  .rdp-button:hover:not([disabled]):not(.rdp-day_selected):not(.rdp-day_today):hover {
    background-color: initial;
  }

  .rdp-day_today:not(.rdp-day_selected) {
    background-color: ${(props) => props.theme.colors.greyBright};

    &:hover {
      background-color: ${(props) => props.theme.colors.greyBright};
    }
  }

  ${props => !props.isRange && '.rdp-day_selected:not([aria-disabled="true"]),'}
  .rdp-day_range_start,
  .rdp-day_range_end,
  .rdp-day_selected:focus:not([aria-disabled='true']) {
    border: 0.0625rem solid ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.white};

    &:hover {
      border: 0.0625rem solid ${(props) => props.theme.colors.black};
      background-color: ${(props) => props.theme.colors.black};
    }
  }

  .rdp-day_range_end {
    border-radius: 0 0.375rem 0.375rem 0;
  }

  .rdp-day_range_start {
    border-radius: 0.375rem 0 0 0.375rem;
  }

  .rdp-day_range_start.rdp-day_range_end {
    border-radius: 0.375rem;
  }

  .rdp-head_cell {
    font: ${(props) => props.theme.fonts.bodyXxs};
    line-height: 1rem;
    letter-spacing: 0em;
    text-align: center;
    color: ${(props) => props.theme.colors.greyMedium};
  }

  .rdp-cell {
    width: calc(100% / 7);
    height: unset;

    button {
      width: 100%;
    }
  }

  .rdp-table {
    width: 100%;
    max-width: unset;
  }

  .rdp-months {
    display: block;
  }

  .rdp-day {
    max-width: unset;
    width: unset;
  }

  .rdp-caption_label {
    color: ${(props) => props.theme.colors.greyMedium};
    font: ${(props) => props.theme.fonts.bodyMSemi};
    z-index: unset;
  }
`;

const ChevronIcon = styled(ChevronLeft) <ChevronIconProps>`
  path {
    fill: ${(props) => props.theme.colors.black};
  }
  transform: ${(props) => (props.$isRotated ? 'rotate(180deg)' : '')};
`;

const Button = styled(UIKitButton)`
  margin-bottom: 1.25rem;
  margin-left: ${props => props.category === 'primary' ? '1.25rem' : '0.625rem'};
`;

const ActionButtonsContainer = styled.div`
  padding: 0;
  margin: 0;
  background-color: ${(props) => props.theme.colors.white};
`;

export default {
  Calendar,
  Container,
  ChevronIcon,
  Button,
  ActionButtonsContainer,
};

import styled, { keyframes } from 'styled-components';

import ReactModalAdapter from './modal-adapter';

import { hex2rgba } from 'utils/style-helpers';
import { DEVICE } from '../../constants/breakpoints';

const showModal = keyframes`
  from {
    transform: translate(-50%, 150%);
  }

  to {
    transform: translate(-50%, -50%);
  }
`


const StyledModal = styled(ReactModalAdapter).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal'
})`
  .Overlay {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => hex2rgba(props.theme.colors.greyMedium, 0.4)};
  }

  .Modal {
    width: 100%;
    right: auto;
    bottom: auto;
    background-color: ${props => props.theme.colors.white};
    position: absolute;
    transition: ${props => props.theme.transition};
    height: 100vh;
    border-radius: 0;
    overflow-y: ${props => props.visibleOverflowY ? 'visible' : 'auto'};

    @media ${DEVICE.tablet} {
      animation: ${showModal} 0.19s cubic-bezier(0.07, 0.65, 0.54, 1.2);
      width: inherit;
      height: auto;
      max-height: 94vh;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      border-radius: 0.5rem;
    }
  }
`;

export default { StyledModal };

import React, { HTMLAttributes, ReactElement, useEffect } from 'react';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';
import S from './styles';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  children: ReactElement;
  shouldCloseOnOverlayClick?: boolean;
  className?: string;
  allowBackgroundScrolling?: boolean;
  openModal?: (isOpen: boolean) => void;
  visibleOverflowY?: boolean;
}

function Modal({
  isOpen,
  shouldCloseOnOverlayClick,
  className,
  children,
  allowBackgroundScrolling,
  openModal,
  visibleOverflowY,
  ...restProps
}: Props): ReactElement {
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  /**
   * if allowBackgroundScrolling is true, document's style and allow it to be scrollable
   */
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = allowBackgroundScrolling ? 'visible' : 'hidden';
    } else {
      document.body.removeAttribute('style');
    }

    return () => {
      document.body.removeAttribute('style');
    };
  }, [isOpen, allowBackgroundScrolling]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <S.StyledModal
          isOpen={isOpen}
          className={className}
          aria-modal={Boolean(isOpen)}
          role='modal'
          shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
          onRequestClose={() => {
            if (openModal) openModal(false);
          }}
          visibleOverflowY={visibleOverflowY}
          {...restProps}
        >
          {children}
        </S.StyledModal>
      </div>
    </ThemeProvider>
  );
}

export default Modal;

import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DateRange, DaySelectionMode } from 'react-day-picker';
import { isAfter, isBefore, isValid } from 'date-fns';
import { usePopper } from 'react-popper';
import { ThemeProvider, useTheme } from 'styled-components';

import { handleTheme } from 'utils/themeHelper';
import {
  DAY_INPUT,
  MONTH_INPUT,
  YEAR_INPUT,
  FROM_DATE,
  TO_DATE,
  SINGLE_DATE,
  initialInputDate,
  ONE_MONTH_VIEW,
} from './constants'
import { CalendarProps, HandlerInputDates, InputDate, Mode } from './types';
import Handle from './handle';
import CalendarContent from './calendar-content';
import { handleRange, getDestructuredDate, isDateValid, getHandlerValuesFromDefaultDate } from './utils';
import useHandleOutsideClick from 'hooks/useHandleOutsideClick';
import S from './styles';

const Calendar = ({
  customHandler,
  isRange = true,
  isPopup,
  placeholder = 'Date range',
  value,
  hasExternalInputFields,
  onChange,
  applyOnSelect,
  displayCustomRanges,
  hideActionButtons,
  className,
  onClear,
  inputError,
}: CalendarProps) => {
  // should only be triggered if isPopup passed as true
  const [isCalendarPopupOpen, setIsCalendarPopupOpen] = useState(false);
  const [mode] = useState((isRange) ? Mode.range : Mode.single);

  // single mode (when isRange = false)
  const [selectedDateInputValue, setSelectedDateInputValue] = useState<InputDate>(initialInputDate);
  const [selectedDateValue, setSelectedDateValue] = useState<Date | undefined>();

  // range mode (when isRange = true)
  const [selectedRange, setSelectedRange] = useState<DateRange>();
  const [fromInputValue, setFromInputValue] = useState<InputDate>(initialInputDate);
  const [toInputValue, setToInputValue] = useState<InputDate>(initialInputDate);
  const [handlerValues, setHandlerValues] =
    useState<HandlerInputDates>(getHandlerValuesFromDefaultDate(isRange, value));

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const popperRef = useRef<HTMLDivElement>(null);
  const handlerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  const closePopper = () => {
    setIsCalendarPopupOpen(false);
  };

  useHandleOutsideClick(wrapperRef, closePopper, isCalendarPopupOpen, true);

  const [error, setError] = useState({
    [FROM_DATE]: false,
    [TO_DATE]: false,
    [SINGLE_DATE]: false,
  });

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'auto'
  });

  useEffect(() => {
    if (!value) {
      return null;
    }

    handleDateSelection(value as DateRange);
  }, [value]);

  const onDateInputFocus = (dateType: string) => () => {
    let inputDateValue;
    let functionToSetValue;
    switch (dateType) {
      case FROM_DATE:
        inputDateValue = fromInputValue;
        functionToSetValue = setFromRange;
        break;
      case TO_DATE:
        inputDateValue = toInputValue;
        functionToSetValue = setToRange;
        break;
      case SINGLE_DATE:
        inputDateValue = selectedDateInputValue;
        functionToSetValue = setSelectedDateValue;
        break;
      default:
        return;
    }

    const { date, someDateValueIsMissing, isDateInValidRange } = handleRange(inputDateValue);
    if (!someDateValueIsMissing && (!isDateInValidRange || !isValid(date))) {
      setError({
        ...error,
        [dateType]: true,
      });
      if (isRange) {
        setSelectedRange({ ...selectedRange, [dateType]: undefined });
      } else {
        setSelectedDateValue(null)
      }

      return;
    }

    setError({
      ...error,
      [dateType]: false,
    });

    if (someDateValueIsMissing) {
      return;
    }

    functionToSetValue(date);
  }

  const setFromRange = (date) => {
    if (selectedRange?.to && isAfter(date, selectedRange.to)) {
      setSelectedRange({ from: selectedRange.to, to: date });
    } else {
      setSelectedRange({ from: date, to: selectedRange?.to });
    }
  }

  const setToRange = (date) => {
    if (selectedRange?.from && isBefore(date, selectedRange.from)) {
      setSelectedRange({ from: date, to: selectedRange.from });
    } else {
      setSelectedRange({ from: selectedRange?.from, to: date });
    }
  }

  useEffect(() => {
    if (applyOnSelect || hideActionButtons) {
      handleOnApply();
    }
  }, [selectedDateValue, selectedRange])

  const setDateForSingeMode = (selection: Date) => {
    const { day, month, year } = getDestructuredDate(selection);
    setSelectedDateInputValue({
      [DAY_INPUT]: day,
      [MONTH_INPUT]: month,
      [YEAR_INPUT]: year,
    });
    setSelectedDateValue(selection);
  };

  const setDateForRange = (selection: DateRange | undefined) => {
    setSelectedRange(selection);
    if (selection?.from) {
      const { day: fromDay, month: fromMonth, year: fromYear } = getDestructuredDate(selection?.from);
      setFromInputValue({
        [DAY_INPUT]: fromDay,
        [MONTH_INPUT]: fromMonth,
        [YEAR_INPUT]: fromYear,
      });
    } else {
      setFromInputValue(initialInputDate)
    }

    if (selection?.to) {
      const { day: toDay, month: toMonth, year: toYear } = getDestructuredDate(selection?.to);
      setToInputValue({
        [DAY_INPUT]: toDay,
        [MONTH_INPUT]: toMonth,
        [YEAR_INPUT]: toYear,
      });
    } else {
      setToInputValue(initialInputDate)
    }

    if (applyOnSelect) {
      handleOnApply();
    }
  };

  const handleDateInputChange = (e: ChangeEvent<HTMLInputElement>, dateType: string, dateInputType: string) => {
    let setFn;
    if (dateType === FROM_DATE) {
      setFn = setFromInputValue;
    } else if (dateType === TO_DATE) {
      setFn = setToInputValue;
    } else {
      setFn = setSelectedDateInputValue;
    }

    setFn(val => ({
      ...val,
      [dateInputType]: e.target.value,
    }));
  };

  const handleDateSelection = (selection: DateRange | undefined) => {
    // check if it's single selection mode
    if (!isRange && !selection?.from && !selection.to) {
      setDateForSingeMode(selection as unknown as Date);

      return;
    }

    // range selection
    if (isRange) {
      setDateForRange(selection);
    }
  };

  const onReset = () => {
    setSelectedRange(null);
    setFromInputValue(initialInputDate);
    setToInputValue(initialInputDate);
    setSelectedDateInputValue(initialInputDate)
    setSelectedDateValue(null);
    setHandlerValues({
      from: initialInputDate,
      to: initialInputDate
    })
    if (onClear) {
      onClear();
    }
  };

  const onHandlerClick = () => {
    setIsCalendarPopupOpen(!isCalendarPopupOpen);
  };

  const getCalendarContent = () => (
    <CalendarContent
      isRange={isRange}
      fromInputValue={fromInputValue}
      handleDateInputChange={handleDateInputChange}
      toInputValue={toInputValue}
      onReset={onReset}
      hasExternalInputFields={hasExternalInputFields}
      numberOfMonths={ONE_MONTH_VIEW}
      selectedRange={selectedRange}
      selectedDateValue={selectedDateValue}
      handleDateSelection={handleDateSelection}
      // mode={mode}
      mode={mode as DaySelectionMode}
      onApply={handleOnApply}
      applyOnSelect={applyOnSelect}
      displayCustomRanges={displayCustomRanges}
      error={error}
      onDateInputFocus={onDateInputFocus}
      hideActionButtons={hideActionButtons}
      className={className}
    />
  );

  // when apply is pressed pass the value to onChange
  const handleOnApply = () => {
    const dateValue = isRange ? selectedRange : selectedDateValue;
    if (Object.values(error).includes(true) || !isDateValid(isRange, dateValue)) {
      return;
    }

    setHandlerValues({
      from: fromInputValue,
      to: toInputValue
    })
    onChange(isRange ? selectedRange : selectedDateValue);
    // close popup after pressing apply
    if (isPopup) {
      setIsCalendarPopupOpen(false);
    }
  };

  const getPopup = () => {
    return (
      isCalendarPopupOpen && (
        <S.PopupContainer
          tabIndex={-1}
          style={popper.styles.popper}
          {...popper.attributes.popper}
          ref={setPopperElement}
          role='dialog'
        >
          {getCalendarContent()}
        </S.PopupContainer>
      )
    )
  };

  return (
    <ThemeProvider theme={theme}>
      {isPopup
        ? (
          <section ref={wrapperRef}>
            <Handle
              customHandler={customHandler}
              hasExternalInputFields={hasExternalInputFields}
              selectedRange={selectedRange}
              handlerRef={handlerRef}
              isCalendarPopupOpen={isCalendarPopupOpen}
              placeholder={placeholder}
              fromInputValue={fromInputValue}
              toInputValue={toInputValue}
              handleDateInputChange={handleDateInputChange}
              isRange={isRange}
              popperRef={popperRef}
              onHandlerClick={onHandlerClick}
              selectedDateInputValue={selectedDateInputValue}
              onDateInputFocus={onDateInputFocus}
              error={error}
              inputError={inputError}
              inputValues={handlerValues}
            />
            {getPopup()}
          </section>
        )
        : getCalendarContent()}
    </ThemeProvider>
  );
};

export default Calendar;

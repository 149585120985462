import styled, { DefaultTheme } from 'styled-components';

interface Props {
    theme: DefaultTheme;
}

interface NameProps {
    small?: boolean;
    theme: DefaultTheme;
}

export const AvatarWrapper = styled.div<Props>`
    box-sizing: border-box;
    margin-right: 0.75rem;
    position: relative;
    display: inline-block;
    text-align: center;
`;

export const AvatarName = styled.p<NameProps>`
    position: absolute;
    color: ${props => props.theme.colors!.white};
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font: ${props => (props.small ? props.theme.fonts.bodyXsBold : props.theme.fonts.h4Bold)};
`;


import { HTMLAttributes, RefObject } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { hex2rgba } from 'utils/style-helpers';

interface TabsProps extends HTMLAttributes<HTMLButtonElement> {
  ref: RefObject<HTMLDivElement>;
  theme: DefaultTheme;
}

export const StyledTabs = styled.div<TabsProps>`
  align-items: flex-start;
  border-radius: 0.4375rem;
  display: flex;
  justify-content: flex-start;
  position: relative;

  &::after {
    background: ${(props) => hex2rgba(props.theme.colors.black, 0.1)};
    bottom: 0.15rem;
    content: '';
    display: block;
    height: 0.0625rem;
    left: 0;
    position: absolute;
    width: 100%;
  }

  @media only screen and (min-width: 48rem) {
    gap: 1.875rem;
  }
`;

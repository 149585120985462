
import styled, { DefaultTheme } from 'styled-components';

interface Props {
  theme?: DefaultTheme;
  isVisible?: boolean;
  size?: string;
  color?: string;
  bottom?: string;
  right?: string;
  styles?: object;
}

export function getOpacity(isVisible: boolean) {
  if (isVisible) {
    return '0.5';
  }

  return '0';
}

export function getPointerEvents(isVisible: boolean) {
  if (isVisible) {
    return 'auto';
  }

  return 'none';
}

export function getSvgColor(props: Props) {
  const {
    theme: { colors },
    color,
  } = props;

  return color ? color : colors.blue;
}

const StyledBackToTop = styled.button<Props>`
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  bottom: ${(props) => props.bottom};
  right: ${(props) => props.right};
  box-shadow: ${(props) => props.theme.boxShadow};
  cursor: pointer;
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  opacity: ${(props) => getOpacity(props.isVisible)};
  pointer-events: ${(props) => getPointerEvents(props.isVisible)};
  outline: none;
  position: fixed;
  transition: ${(props) => props.theme.transition};
  z-index: 90;
  svg {
    fill: ${getSvgColor};
  }

  ${(props) => ({ ...props.styles })}
`;

export default StyledBackToTop;

import React, { FunctionComponent, ReactElement } from 'react';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';
import {
  BeneficiaryOptionWrapper,
  BeneficiaryOptionIcon,
  BeneficiaryOptionColumn,
  BeneficiaryOptionName,
  BeneficiaryOptionTrustedIcon,
  BeneficiaryOptionBottomLine,
  BeneficiaryOptionCurrency,
} from './styles';

interface Props {
  accountNumber?: string;
  amount?: string;
  name?: string;
  avatar?: FunctionComponent;
  sortCode?: string;
  currencyShort?: string;
  isTrusted?: boolean;
}

function BeneficiaryOption(props: Props): ReactElement {
  const { avatar: Avatar, name, sortCode, accountNumber, currencyShort, isTrusted } = props;
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  return (
    <ThemeProvider theme={theme}>
      <BeneficiaryOptionWrapper>
        {Avatar && (
          <BeneficiaryOptionIcon><Avatar /></BeneficiaryOptionIcon>
        )}
        <BeneficiaryOptionColumn>
          {name &&
            <BeneficiaryOptionName>
              {name}
              {isTrusted && <BeneficiaryOptionTrustedIcon />}
            </BeneficiaryOptionName>}
          <BeneficiaryOptionBottomLine>
            {currencyShort && <BeneficiaryOptionCurrency>{currencyShort} •</BeneficiaryOptionCurrency>}
            {accountNumber && <span>{accountNumber} {sortCode && '•'}</span>}
            {sortCode && <span>{sortCode}</span>}
          </BeneficiaryOptionBottomLine>
        </BeneficiaryOptionColumn>
      </BeneficiaryOptionWrapper>
    </ThemeProvider>
  );
}

export default BeneficiaryOption;

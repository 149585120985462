import React, { HTMLProps } from 'react';
import { ThemeProvider, useTheme } from 'styled-components';
import S from './styles';

import { handleTheme } from 'utils/themeHelper';

export interface Props extends HTMLProps<HTMLButtonElement> {
  label?: string;
  disabled?: boolean;
  onClick?: () => void;
  icon: React.ReactElement;
  className?: string;
  selected?: boolean;
}

function ActionButton({
  label,
  icon,
  className,
  selected,
  disabled,
  onClick,
}: Props): JSX.Element {
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  return (
    <ThemeProvider theme={theme}>
      <S.ActionButtonContainer
        aria-label={label}
        className={className}
        disabled={disabled}
        onClick={disabled ? null : onClick}
      >
        <S.ActionButton selected={selected} >
          <S.Icon selected={selected}>{icon}</S.Icon>
        </S.ActionButton>
        {label && (<S.Label>{label}</S.Label>)}
      </S.ActionButtonContainer>
    </ThemeProvider>
  )
}

export default ActionButton;

import styled, { keyframes } from 'styled-components';

// TODO: Fix build config
// https://ftcs-tech.atlassian.net/browse/IDE-14749
import intergiroLogo from './logo';

interface Props {
  size: string;
  animation: {
    duration: string
  };
}

const play = keyframes`
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: -2304px 0px;
  }
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;
  z-index: 2;
`;

const Logo = styled.div<Props>`
  background-image: url(${intergiroLogo});
  background-repeat: no-repeat;
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  animation-name: ${play};
  animation-duration: ${(props) => props.animation.duration};
  animation-timing-function: steps(48);
  animation-iteration-count: infinite;
  position: absolute;
  top: 50%;
  margin-top: -66px;
  z-index: 10054;
`;

export default {
  LogoWrapper,
  Logo,
}

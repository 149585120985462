export const scoreComplexityColors = {
    default: {
      0: 'red',
      1: 'red',
      2: 'yellow',
      3: 'green',
      4: 'green',
    },
    high: {
      0: 'red',
      1: 'red',
      2: 'red',
      3: 'yellow',
      4: 'green',
    }
};

export const DEFAULT_SCORE_COMPLEXITY = 'default';

export const scoreLabelsMap = {
    high: ['Weak', 'Good', 'Great'],
    default: ['Weak', 'Okay', 'Good', 'Strong'],
};

export const minScoreMap = {
    high: 3,
    default: 2
};

import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';
import { StyledError } from 'helpers/field';
import StyledPhoneInput from './styles';

interface Props {
  className?: string;
  placeholder?: string;
  error?: string;
  country?: string;
  onChange?: (name: string, value?: string) => void;
  name?: string;
  id?: string;
  focusedBorderColor?: string;
  disabled?: boolean;
  styles?: object;
  value?: string;
  label?: string;
  autoFocus?: boolean;
}

function PhoneInput(props: Props) {
  const {
    error,
    onChange = () => {
      /**/
    },
    disabled,
    className,
    styles,
    label = '',
    id,
    name,
    autoFocus,
  } = props;
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  return (
    <ThemeProvider theme={theme}>
      <StyledPhoneInput styles={styles} label={label} error={error} disabled={disabled} className={className}>
        <ReactPhoneInput
          {...props}
          onChange={(value) => onChange(name, value)}
          disabled={disabled}
          placeholder='PhoneInput'
          enableSearch={true}
          disableSearchIcon={true}
          searchPlaceholder=''
          inputProps={{ id, name, autoFocus }}
        />
        {error && <StyledError>{error}</StyledError>}
      </StyledPhoneInput>
    </ThemeProvider>
  );
}

export default PhoneInput;

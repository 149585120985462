import styled from 'styled-components';

import { TrustedIcon } from 'assets';

export const BeneficiaryOptionWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const BeneficiaryOptionIcon = styled.div`
  box-sizing: border-box;
  height: 2rem;
  margin-right: 0.75rem;
  width: 2rem;
`;

export const BeneficiaryOptionColumn = styled.div`
  display: block;
  max-width: calc(100% - 2.75rem);
`;

export const BeneficiaryOptionName = styled.div`
  display: block;
  margin-right: 0.3rem;
  font: ${(props) => props.theme.fonts.bodyS};
  padding-bottom: 0.25rem;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const BeneficiaryOptionTrustedIcon = styled(TrustedIcon)`
  width: 0.875rem;
  height: 0.875rem;
  margin-left: 0.25rem;
  vertical-align: baseline;
  path {
    fill: ${(props) => props.theme.colors!.greyMedium};
    fill-rule: evenodd;
  }
`;

export const BeneficiaryOptionBottomLine = styled.div`
  color: ${(props) => props.theme.colors!.greyMedium};
  font: ${(props) => props.theme.fonts.bodyXsSemi};
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;


export const BeneficiaryOptionCurrency = styled.span`
  margin-right: 0.25rem;
`;

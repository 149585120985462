const uppercaseLetterRegex = /(.*[A-Z].*)/;
const digitRegex = /(.*\d.*)/;
const nonWordCharacterRegex = /(.*\W.*)/;
const goodPasswordLength = 8;

type PasswordStrength = 0 | 1 | 2 | 3 | 4;

function calculatePasswordStrength(password: string): PasswordStrength {
  if (password === null || password === undefined) {
    throw Error('Password has to be provided');
  }

  let score = 0;

  if (password.length >= goodPasswordLength) {
    score++;
  } else {
    return score as PasswordStrength;
  }

  if (uppercaseLetterRegex.test(password)) {
    score++;
  }
  if (digitRegex.test(password)) {
    score++;
  }
  if (nonWordCharacterRegex.test(password)) {
    score++;
  }

  return score as PasswordStrength;
}

export { calculatePasswordStrength };

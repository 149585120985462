import qs from 'qs';
import defaultsDeep from 'lodash/defaultsDeep';
import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';

export function getQueryObject(location) {
  const queryString = location.search ? location.search.slice(1) : '';
  const queryObj = fromApiFormat(qs.parse(queryString));

  return queryObj;
}

export function getStartPageQueryObject(location) {
  const queryObj = getQueryObject(location);

  // TODO: https://ftcs-tech.atlassian.net/browse/IDE-10938
  if (queryObj.meta) {
    delete queryObj.meta.endingBefore;
    delete queryObj.meta.startingAfter;
  }

  return queryObj;
}

// TODO: rethink and rework 'updateQuery'. Merge objects and arrays are not obvious and explicit.
// 'defaultsDeep' has a merge arrays problems https://github.com/lodash/lodash/issues/3702
// https://ftcs-tech.atlassian.net/browse/IDE-6347
export function updateQuery(history, location, nextQuery, currentQuery = getQueryObject(location)) {
  const result = defaultsDeep({}, nextQuery, currentQuery);
  const queryString = getQueryString(result);
  history.replace({ pathname: location.pathname, search: queryString });

  return result;
}

export function getQueryString(query) {
  return `?${qs.stringify(toApiFormat(query), { arrayFormat: 'brackets' })}`;
}

// inspired by http://stackoverflow.com/a/26215431/938193
function convertPropertyNames(obj, converter) {
  let convertedObj;

  if (Array.isArray(obj)) {
    convertedObj = obj.map((el) => convertPropertyNames(el, converter));
  } else if (Object.prototype.toString.call(obj) === '[object Object]') {
    // convert custom objects only, not Date, etc.
    convertedObj = {};
    for (const key of Object.keys(obj)) {
      const destKey = (key || '').replace(/[^.\][]+/g, converter);
      const value = obj[key];

      convertedObj[destKey] = convertPropertyNames(value, converter);
    }

    const isCustomerObject = (convertedObj.firstName || convertedObj.lastName) && !convertedObj.name;
    if (isCustomerObject) {
      convertedObj.name = `${convertedObj.firstName || ''} ${convertedObj.lastName || ''}`.trim();
    }
  } else {
    convertedObj = obj;
  }

  return convertedObj;
}

function toApiFormat(dto) {
  return convertPropertyNames(dto, snakeCase);
}

function fromApiFormat(dto) {
  return convertPropertyNames(dto, camelCase);
}

import React, { Fragment } from 'react';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';
import S from './styles';

export interface ValuesProps {
  name: string;
  value: string;
  selected: boolean;
  disabled?: boolean;
}

export interface Props  {
  values: ValuesProps[];
  className?: string;
  id: string;
  handleChange: (value: string) => void;
}

// TODO trim label when it's too long and add "..." instead
function Toggle({values, id, handleChange, className}: Props): JSX.Element {
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  return (
    <ThemeProvider theme={theme}>
      <S.Container className={className}>
        {values.map(({name, value, selected, disabled}) => (
          <Fragment key={value}>
            <S.Toggle
              id={name}
              name={id}
              value={value}
              type='radio'
              checked={selected}
              onChange={() => disabled ? null : handleChange(value)}
            />
            <S.Label
              htmlFor={name}
              active={selected}
              numberOfElements={values.length}
              disabled={disabled}
            >
              {name}
            </S.Label>
          </Fragment >
        ))}
      </S.Container>
    </ThemeProvider>
  );
}

export default Toggle;

import React, { ReactNode } from 'react';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';
import S from './styles';

export interface Props {
  name: string;
  id: string;
  label?: string;
  checked: boolean;
  className?: string;
  customLabel?: Node | ReactNode;
  onChange?: (e, name: string) => void;
  disabled?: boolean;
}

function RadioButton({
  name,
  label,
  id,
  className,
  checked,
  customLabel,
  onChange,
  disabled = false,
}: Props): JSX.Element {

  const handleChange = (e) => {
    onChange(e, name);
  };
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  return (
    <ThemeProvider theme={theme}>
      <S.Container className={className}>
        <S.InputContainer>
          <S.Input
            id={id}
            type='radio'
            name={name}
            checked={checked}
            onChange={e => handleChange(e)}
            disabled={disabled}
          />
          <S.CustomInput />
        </S.InputContainer>
        {customLabel ? (
          <>{customLabel}</>
        ) : (
          <S.Label>{label}</S.Label>
        )}
      </S.Container>
    </ThemeProvider>
  );
}

export default RadioButton;

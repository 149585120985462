import React, { HTMLProps, ReactNode } from 'react';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';
import { CheckIcon } from 'assets';
import { StyledCheckbox, StyledLabel, StyledIntermediateWrapper, StyledIntermediateIcon } from './styles';

interface Props extends HTMLProps<HTMLInputElement> {
  children?: ReactNode;
  className?: string;
  intermediate?: boolean;
  isInverse?: boolean;
  styles?: object;
  width?: string;
}

function Checkbox(props: Props): JSX.Element {
  const {
    children,
    styles,
    width = '1.125rem',
    className,
    isInverse,
    intermediate,
    ref,
    as,
    ...checkboxProps
  } = props;
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  return (
    <ThemeProvider theme={theme}>
      <StyledLabel styles={styles} className={className} htmlFor={checkboxProps.id}>
        <StyledCheckbox
          as={as as any}
          isInverse={isInverse}
          ref={ref as any}
          type='checkbox'
          width={width}
          {...checkboxProps}
        />
        <CheckIcon />
        {intermediate && (
          <StyledIntermediateWrapper width={width}>
            <StyledIntermediateIcon />
          </StyledIntermediateWrapper>
        )}
        {children && <span>{children}</span>}
      </StyledLabel>
    </ThemeProvider>
  );
}

export default Checkbox;

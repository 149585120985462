export const DAY_INPUT = 'day';
export const MONTH_INPUT = 'month';
export const YEAR_INPUT = 'year';
export const DEFAULT_INPUT = '--';
export const MIN_YEAR = 2020;
export const ONE_MONTH_VIEW = 1;

export const FROM_DATE = 'from';
export const TO_DATE = 'to';
export const SINGLE_DATE = 'singleDate';

export const initialInputDate = {day: '', month: '', year: ''};

export const dateValidationRulesMap = {
  day: {
    regex: /^$|\b(0?[1-9]|[1-2][0-9]|3[0-1])\b/,
    length: 2,
  },
  month: {
    regex: /^$|^(0?[1-9]|1[0-2])$/,
    length: 2,
  },
  year: {
    regex: /^(181[2-9]|18[2-9]\d|19\d\d|2\d{3}|30[0-3]\d|304[0-8])$/,
    length: 4,
  },
};

export const CUSTOM = 'custom';
export const MONTH_TO_DATE = 'monthToDate';
export const QUARTER_TO_DATE = 'quarterToDate';
export const YEAR_TO_DATE = 'yearToDate';
export const LAST_MONTH = 'lastMonth';
export const LAST_QUARTER = 'lastQuarter';
export const LAST_YEAR = 'lastYear';

export const rangeOptions = [
  { label: 'Custom', value: CUSTOM, checked: false, id: CUSTOM },
  { label: 'Month-to-date', value: MONTH_TO_DATE, checked: false, id: MONTH_TO_DATE },
  { label: 'Quarter-to-date', value: QUARTER_TO_DATE, checked: false, id: QUARTER_TO_DATE },
  { label: 'Year-to-date', value: YEAR_TO_DATE, checked: false, id: YEAR_TO_DATE },
  { label: 'Last month', value: LAST_MONTH, checked: false, id: LAST_MONTH },
  { label: 'Last quarter', value: LAST_QUARTER, checked: false, id: LAST_QUARTER },
  { label: 'Last year', value: LAST_YEAR, checked: false, id: LAST_YEAR },
];

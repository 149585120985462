import * as React from 'react';
import StyledTitle from './styles';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';
interface Props {
  fontSize?: string;
  tag?: string;
  children: any;
  styles?: object;
  className?: string;
}

function Title(props: Props): JSX.Element {
  const { children, tag = 'h1', fontSize = '1.75rem', styles, className } = props;
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  return (
    <ThemeProvider theme={theme}>
      <StyledTitle {...props} as={tag as any} styles={styles} fontSize={fontSize} className={className}>
        {children}
      </StyledTitle>
    </ThemeProvider>
  );
}

export default Title;

import React from 'react';

import Header from 'components/calendar/header';

import { CalendarContentTypes } from '../types'
import { isDateValid } from '../utils'
import S from './styles';

const CalendarContent = ({
  isRange,
  fromInputValue,
  handleDateInputChange,
  toInputValue,
  onReset,
  hasExternalInputFields,
  numberOfMonths,
  selectedRange,
  selectedDateValue,
  handleDateSelection,
  mode,
  onApply,
  displayCustomRanges,
  error,
  onDateInputFocus,
  applyOnSelect,
  hideActionButtons,
  className,
}: CalendarContentTypes) => {
  const applyIsDisabled = !isDateValid(isRange, isRange ? selectedRange : selectedDateValue);

  return (
    <S.Container isRange={isRange} hasExternalInputFields={hasExternalInputFields} className={className}>
      <Header
        fromInputValue={fromInputValue}
        handleDateInputChange={handleDateInputChange}
        toInputValue={toInputValue}
        isRange={isRange}
        displayCustomRanges={displayCustomRanges}
        handleDateSelection={handleDateSelection}
        onDateInputFocus={onDateInputFocus}
        error={error}
      />
      {/* @ts-ignore */}
      <S.Calendar
        mode={mode}
        numberOfMonths={numberOfMonths}
        selected={isRange ? selectedRange : selectedDateValue}
        // @ts-ignore
        onSelect={handleDateSelection}
        showOutsideDays
        fixedWeeks
        weekStartsOn={1}
        disabled= {{ after: new Date() }}
        components={{
          IconLeft: () => <S.ChevronIcon />,
          IconRight: () => <S.ChevronIcon $isRotated />
        }}
      />
      {!hideActionButtons && (
        <S.ActionButtonsContainer>
          {!applyOnSelect &&
            <S.Button label='Apply' category='primary' size='small' onClick={onApply} disabled={applyIsDisabled} />
          }
          <S.Button label='Clear' category='secondary' size='small' onClick={onReset} />
        </S.ActionButtonsContainer>
      )}
    </S.Container>
  );
};

export default CalendarContent;

import React from 'react';
import { components } from 'react-select';

import {CloseIcon} from 'assets';

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <CloseIcon />
    </components.MultiValueRemove>
  );
};

export default MultiValueRemove;

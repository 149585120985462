import React from 'react';
import { components } from 'react-select';
import { ENTER } from '../../../../constants/keys';

import S from './styles';

function SingleValue(props): JSX.Element {
  const { name, id, isCreatable, onEditChange } = props.selectProps;

  const onInputChange = (e) => {
    e.stopPropagation();
    const newOption = {
      label: e.target.value,
      value: e.target.value
    }

    props.setValue(newOption);
  };

  const handleChange = onEditChange || onInputChange;

  const onKeyDown = e => {
    if (e.target.value.length > 0) {
      // important: this will allow the use of backspace in the input
      e.stopPropagation();
    } else if (e.key === ENTER) {
      // if value is empty, press enter to delete custom option
      e.stopPropagation();
      props.setValue(null);
    }
  };

  return props.data.__isNew__ || isCreatable ? (
    <S.SingleValueStyle
      key={id}
      id={id}
      name={name}
      type='text'
      value={props.data.label}
      onChange={e => handleChange(e)}
      onKeyDown={e => onKeyDown(e)}
    />
  ) : (
    <components.SingleValue {...props} />
  );
};

export default SingleValue;

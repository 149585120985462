import styled from 'styled-components';

import { FilterDropdown } from 'components/dropdown';
import DateInput from 'components/calendar/date-input';

// custom header
const HeaderContainer = styled.div`
  background: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  border-bottom: 0.0625rem solid ${(props) => props.theme.colors.greyBright};
  padding-bottom: 1rem;
`;

const HeaderInput = styled.div`
  padding: 1.25rem 1.25rem 0 1.25rem;
  background: white;
`;

const DateInputContainer = styled(DateInput)`
  background-color: ${(props) => props.theme.colors.greyBright};
  border-radius: 0.5rem;
  color: ${(props) => props.theme.colors.black};
  font: ${(props) => props.theme.fonts.bodyS};
  padding: 0.625rem;
  width: 100%;

  &__input:invalid {
    background: ${(props) => props.theme.colors.greyBright};
  }
`;

const DateRangeDivider = styled.span`
  padding: 0 0.3125rem;
`;

const CustomRangeDropdown = styled(FilterDropdown)`
  padding-bottom: 1.4375rem;
`;

export default {
  DateInputContainer,
  DateRangeDivider,
  HeaderContainer,
  HeaderInput,
  CustomRangeDropdown,
};

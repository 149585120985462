import styled, { DefaultTheme } from 'styled-components';


interface ContainerProps {
  theme: DefaultTheme;
}
interface LabelProps {
  theme: DefaultTheme;
}
interface InputProps {
  theme: DefaultTheme;
}

export const InputContainer = styled.span`
  display: flex;
`;

export const Label = styled.span<LabelProps>`
  line-height: 1;
`;

export const Container = styled.label<ContainerProps>`
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.5625rem;
`;

export const CustomInput = styled.span<InputProps>`
  box-sizing: border-box;
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  border: 0.125rem solid ${props => props.theme.colors!.black};
  transform: translateY(-0.05em);
`;

export const Input = styled.input<InputProps>`
  display: none;
  box-sizing: border-box;
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${CustomInput} {
    box-sizing: border-box;
    border: 0.375rem solid ${props => props.theme.colors!.black};
  }
`;

export default { Container, Input, Label, CustomInput, InputContainer };

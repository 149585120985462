export function convertRemToPixels(rem) {
  const defaultFontSize = getComputedStyle(document.documentElement).fontSize || '16';

  return rem * parseFloat(defaultFontSize);
}

export function hex2rgba(hex: string, alpha: number = 1) {
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));

  return `rgba(${r},${g},${b},${alpha})`;
}

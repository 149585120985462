import React from 'react';
import ReactModal from 'react-modal';

function ReactModalAdapter({ className, modalClassName, overlayClassName, ...props }) {
    ReactModal.setAppElement('body');

    return (
        <ReactModal
            className={modalClassName}
            portalClassName={className}
            overlayClassName={overlayClassName}
            appElement={document.getElementById('root')}
            {...props}
        />
    );
}

export default ReactModalAdapter;

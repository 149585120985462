import React, {
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';

import S from './styles';

const DropdownFilterControl = forwardRef<
  any,
  PropsWithChildren<{
    className?: string;
    hideArrow?: boolean;
    hideOnClick?: boolean;
    label: string | ReactNode;
    openToLeft?: boolean;
  }>
>(({ children, hideArrow = false, label, hideOnClick = false, openToLeft = false, className }, parentRef) => {
  const [visible, setVisibility] = useState(false);
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  const ref = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  function handleDocumentClick(event: MouseEvent) {
    if (ref.current.contains(event.target) && !hideOnClick) {
      return;
    }

    setVisibility(false);
  }

  function handleDropdownClick() {
    setVisibility(!visible);
  }

  useImperativeHandle(parentRef, () => ({
    closeDropdown() {
      setVisibility(false);
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <S.Bar ref={ref} className={className}>
        <S.FilterBarButton onClick={handleDropdownClick}>
          {label} {!hideArrow && <S.ArrowDown $isVisible={visible} />}
        </S.FilterBarButton>
        <S.Wrapper openToLeft={openToLeft}>
          <S.Container $visible={visible}>
            {children}
          </S.Container>
        </S.Wrapper>
      </S.Bar>
    </ThemeProvider>
  );
});

export default DropdownFilterControl;

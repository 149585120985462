import styled, { DefaultTheme } from 'styled-components';

import { HTMLProps } from 'react';
import { CurrencyOptionColumn, CurrencyOptionBottomLineWrapper } from './components/currency-option/styles';
import { MagnifyingGlassIcon } from 'assets';

interface Props extends HTMLProps<HTMLDivElement> {
  theme: DefaultTheme;
  styles?: object;
  error?: string | boolean;
  $rotate?: boolean;
}

interface LabelProps {
  hasPlaceholder: boolean;
  isActive: boolean;
  isDisabled?: boolean;
  isBeneficiaryOption: boolean;
  theme: DefaultTheme;
}

export function getColor(props: Props) {
  const {
    error,
    theme: { colors },
  } = props;

  return error ? colors.red : colors.blue;
}

export const StyledLabel = styled.span<LabelProps>`
  position: absolute;
  top: ${(props) => (props.isActive || props.hasPlaceholder ? '0.7rem' : '1.5rem')};
  left: calc(1rem + 2px);
  transition: ${(props) => props.theme.transition};
  color: ${(props) => props.theme.colors!.greyMedium};
  font: ${(props) => (props.isActive || props.hasPlaceholder ? props.theme.fonts.bodyXs : props.theme.fonts.bodyS)};
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.isDisabled ? '0.5' : '1')};
  pointer-events: none;
`;

export const StyledHintWrap = styled.span`
  pointer-events: auto;
`

export const StyledSelect = styled.div<Props>`
  position: relative;
  color: ${(props) => props.theme.colors!.black};
  font: ${(props) => props.theme.fonts.bodyLSemi};
  text-align: left;
  ${(props) => ({ ...props.styles })};

  .select__control {
    border-width: 2px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors!.greyBright};
    box-sizing: border-box;
    background-color: ${(props) => props.theme.colors!.greyBright};
    cursor: pointer;
    font: inherit;
    transition: ${(props) => props.theme.transition};
    padding: 1.6rem 1rem 0.35rem;
    border-radius: 0.5rem;
    flex-wrap: nowrap;
  }

  select__control:enabled:hover {
    border-color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.greyLight)};
    outline: none;
  }

  .select__control--is-focused,
  .select__control--is-focused:hover {
    border-color: ${(props) => props.theme.colors!.blue};
    box-shadow: none;
  }

  .select__control--menu-is-open {
    border-color: ${(props) => props.theme.colors!.blue};

    .select__dropdown-indicator {
      transform: ${({ $rotate }) => $rotate && 'rotate(180deg)'};
    }

    + .select__menu {
      ${CurrencyOptionColumn} {
        align-items: flex-start;
        flex-direction: column;
      }

      ${CurrencyOptionBottomLineWrapper} {
        font: ${(props) => props.theme.fonts.bodyXs};
      }
    }
  }

  .select__menu {
    box-shadow: ${(props) => props.theme.boxShadow};
    border-radius: 0.5rem;
    margin-top: 0.125rem;
    background-color: ${(props) => props.theme.colors!.white};
    z-index: 10;
  }

  .select__menu-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  .select__option:active {
    background-color: ${(props) => props.theme.colors!.greyLight};
  }

  .select__option {
    background-color: ${(props) => props.theme.colors!.white};
    padding: 0.625rem 1rem;
    cursor: pointer;
    color: inherit;
    font: ${(props) => props.theme.fonts.bodyS};

    &--is-focused {
      background-color: ${(props) => props.theme.colors!.greyBright};
    }

    &--is-disabled {
      color: ${props => props.theme.colors!.greyMedium};
    }
  }

  .select--is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .select__indicators {
    align-items: flex-start;
  }

  .select__indicator {
    padding: 0 0.3rem;
  }

  .select__clear-indicator svg {
    fill: ${(props) => props.theme.colors!.greyMedium};
    width: 1rem;
    height: 1rem;
  }

  .select__dropdown-indicator svg {
    padding-top: 0.2rem;
    fill: ${(props) => props.theme.colors!.greyMedium};
    box-sizing: content-box;
  }

  .select__menu-notice--no-options {
    color: ${(props) => props.theme.colors!.greyMedium};
    font: ${(props) => props.theme.fonts.bodyS};
    text-align: left;
    padding-left: 1rem;
  }

  .select__input input {
    color: inherit;
    font: ${(props) => props.theme.fonts.bodyL};
  }

  .select__value-container {
    padding-left: 0;
    width: 85%;
  }

  .select__multi-value {
    background-color: ${(props) => props.theme.colors!.greyDarkest};
    font: ${(props) => props.theme.fonts.bodySSemi};
    border-radius: 0.25rem;
    width: fit-content;

    &:first-of-type{
      margin-left: 0;
    }

    &__label {
      padding: 0.15rem 0.5rem;
      color: ${(props) => props.theme.colors!.white};
      white-space: normal;
    }

    &__remove {
      margin-left: -.5rem;
      padding-left: 0.4375rem;
      padding-right: 0.4375rem;
      background-color: ${(props) => props.theme.colors!.greyDarkest};
      border-radius: 0.25rem;
      &:hover {
        background-color: ${(props) => props.theme.colors!.greyDark};
      }

      svg path {
        fill: ${(props) => props.theme.colors!.greyLight};
      }
    }
  }

  .select__single-value {
    width: 100%;
  }

  .error .select__control {
    border-color: ${(props) => props.theme.colors.red};
  }
`;

export const MagnifyingGlass = styled(MagnifyingGlassIcon)`
  width: 1.125rem;
  path {
    fill: ${(props) => props.theme.colors!.greyMedium};
  }
`;

import React, { useEffect, useState } from 'react';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';
import { StyledTrigger, StyledTooltip, QuestionMarkIcon, WarningIcon } from './styles';
import { usePopperTooltip } from 'react-popper-tooltip';

export interface Props {
  className?: string;
  variant?: 'question-mark' | 'warning';
  text: string;
}

function Hint({ text, className, variant = 'question-mark' }: Props) {
  const HintIcon = variant === 'question-mark' ? QuestionMarkIcon : WarningIcon;
  const isTablet = window.innerWidth <= 768;
  const [translate3d, setTranslate3d] = useState(null);
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible, tooltipRef, triggerRef } = usePopperTooltip({
    trigger: isTablet ? 'click' : 'hover',
    placement: 'top',
  });
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  /**
   * useEffect makes sure that the tooltip is not located on the edge of the viewport
   * by calculating the distance to left and right edges of the viewport
   * if the distance is less than 10px additional 10 px will be added to the respective side
   */
  useEffect(() => {
    if (!tooltipRef) return;

    // setTimeout has 0ms delay in order to wait for react-popper-tooltip to apply its styles
    setTimeout(() => {
      const { right: triggerRefRight, left: triggerRefLeft } = triggerRef?.getBoundingClientRect();
      const { width: tooltipRefWidth } = tooltipRef?.getBoundingClientRect();
      const triggerDistanceToRight = window.innerWidth - triggerRefRight;
      const distanceToRight = triggerDistanceToRight - (tooltipRefWidth / 2);
      const distanceToLeft = triggerRefLeft - (tooltipRefWidth / 2);
      const translate3dRegex = /translate3d\((?<x>.*?)px, (?<y>.*?)px, (?<z>.*?)px/
      const translate3dObj = translate3dRegex.exec(tooltipRef?.style.transform);

      if (distanceToRight < 10) {
        setTranslate3d(`translate3d(${Number(translate3dObj.groups.x) - 10}px, ${translate3dObj.groups.y}px, ${translate3dObj.groups.z}px)`);
      }
      if (distanceToLeft < 10) {
        setTranslate3d(`translate3d(${Number(translate3dObj.groups.x) + 10}px, ${translate3dObj.groups.y}px, ${translate3dObj.groups.z}px)`);
      }
    }, 0);
  }, [
    triggerRef?.getBoundingClientRect()?.left,
    triggerRef?.getBoundingClientRect()?.right,
    tooltipRef?.getBoundingClientRect()?.width,
    window.innerWidth,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <>
        <StyledTrigger className={className} ref={setTriggerRef}>
          <HintIcon />
        </StyledTrigger>

        {visible && (
          <StyledTooltip {...getTooltipProps()} ref={setTooltipRef} translate3d={translate3d}>
            {text}
          </StyledTooltip>
        )}
      </>
    </ThemeProvider>
  );
}

export default Hint;

import React, { useState, useEffect, useRef } from 'react';
import GlobalLoader from 'components/global-loader';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';

interface Props {
  loading?: boolean;
  loaderComponent?: JSX.Element,
  delay?: number,
}

const IDLE = 'IDLE';
const DISPLAY = 'DISPLAY';
const DELAY = 'DELAY';

// inspired by https://github.com/smeijer/spin-delay/blob/master/src/index.ts

function LoaderWithDelay({
  loading = false,
  loaderComponent = <GlobalLoader />,
  delay = 1000,
}: Props): JSX.Element {
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);
  const [state, setState] = useState(IDLE);
  const timeout = useRef(null);

  useEffect(() => {
    if (loading && state === IDLE) {
      clearTimeout(timeout.current);

      timeout.current = setTimeout(() => {
        if (!loading) {
          return setState(IDLE);
        }

        setState(DISPLAY);
      }, delay);

      setState(DELAY);
    }

      if (!loading) {
        clearTimeout(timeout.current);
        setState(IDLE);
      }


  }, [loading, state, delay]);

  useEffect(() => {
      return () => clearTimeout(timeout.current);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div>
        {(state === DISPLAY) && loaderComponent}
      </div>
    </ThemeProvider>
  );
}

export default LoaderWithDelay;

import * as React from 'react';
import { DefaultTheme } from 'styled-components';

import { ButtonContentLabel } from './styles';

interface Props {
  label?: string;
  icon?: React.ReactElement;
}

function ButtonContent(props: Props) {
  const { icon, label } = props;

  if (icon) {
    return (
      <>
        {icon} <ButtonContentLabel>{label}</ButtonContentLabel>
      </>
    );
  }

  return <ButtonContentLabel>{label}</ButtonContentLabel>;
}

export default ButtonContent;

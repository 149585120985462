import React, { HTMLProps, useRef, useState, ReactNode, ChangeEvent, forwardRef, RefObject } from 'react';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';
import isEmpty from 'lodash/isEmpty';

import { CrossIcon, CrossedEyeIcon, EyeIcon } from 'assets';

import { StyledError, StyledAssistiveText } from 'helpers/field';
import Hint from 'components/hint';
import { generateUniqueId } from 'utils/generateUniqueId';
import { StyledInput, StyledLink, StyledWrapper, StyledHelperText, StyledButton } from './styles';
import { Score } from './components';
import { calculatePasswordStrength } from 'utils/passwordStrength';
import { ScoreChangeEvent, ScoreComplexity } from './components/types';

interface Props extends HTMLProps<HTMLInputElement> {
  assistiveText?: string;
  error?: string;
  helperText?: ReactNode;
  hint?: string;
  inputPattern?: RegExp;
  isCheckingStrength?: boolean;
  scoreComplexity?: ScoreComplexity;
  isClearable?: boolean;
  isInverse?: boolean;
  isPassword?: boolean;
  label: string;
  link?: ReactNode;
  onClear?: () => void;
  onScoreChange?: (scoreEvent: ScoreChangeEvent) => void;
  rtl?: boolean;
  showPasswordIcons?: boolean,
  styles?: object;
}

const Input = forwardRef<RefObject<HTMLInputElement>, Props>((props, ref: any): JSX.Element => {
  const {
    as,
    assistiveText,
    className,
    error,
    helperText,
    hint,
    id,
    inputPattern,
    isCheckingStrength,
    scoreComplexity,
    isClearable = false,
    isInverse,
    isPassword,
    label = '',
    link,
    onChange = () => {
      /**/
    },
    onClear,
    onScoreChange,
    placeholder,
    rtl,
    showPasswordIcons = false,
    styles,
    ...inputProps
  } = props;
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  const inputRef = ref || useRef(null);
  const inputId = id || generateUniqueId();

  const [isLinkVisible, setLinkVisibility] = useState(true);
  const [score, setScore] = useState(null);
  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const getPasswordIcon = () => (isPasswordVisible ? <CrossedEyeIcon /> : <EyeIcon />);

  const clearInput = (e) => {
    e.preventDefault();

    onClear ? onClear() : (inputRef.current.value = '');
  };

  const getButton = () => {
    const icon = isPassword ? getPasswordIcon() : <CrossIcon />;
    const onClick = isPassword ? togglePasswordVisibility : clearInput;
    let action;

    if (isPassword) {
      action = 'password';
    } else if (isClearable) {
      action = 'clear';
    } else {
      action = 'none';
    }

    return showPasswordIcons || isClearable ? (
      <StyledButton
        id={`${inputId}${action.charAt(0).toUpperCase() + action.slice(1)}`}
        onClick={onClick}
        action={action}
        type='button'
        tabIndex={-1}
        rtl={rtl}
      >
        {icon}
      </StyledButton>
    ) : null;
  };

  const getLink = (): ReactNode => {
    return isLinkVisible && <StyledLink rtl={rtl}>{link}</StyledLink>;
  };

  const getHelperText = (): ReactNode => <StyledHelperText rtl={rtl}>{helperText}</StyledHelperText>;

  const getScore = (e) => {
    const { value } = e.target;
    const passwordScore = value ? calculatePasswordStrength(value) : null;
    setScore(passwordScore);
    handleChange(e);
  };

  const getType = () => {
    if (showPasswordIcons) {
      return isPassword && !isPasswordVisible ? 'password' : inputProps.type;
    }

    return isPassword ? 'password' : inputProps.type;
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const testPinRegEx = new RegExp(inputPattern);
    if (testPinRegEx && !testPinRegEx.test(e.target.value) && e.target.value !== '') {
      return;
    }

    setLinkVisibility(isEmpty(e.target.value));
    onChange(e);
  };

  const togglePasswordVisibility = (e) => {
    e.preventDefault();

    setPasswordVisibility((isVisible) => !isVisible);
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledInput
        styles={styles}
        as={as as any}
        error={error}
        className={className}
        hasPlaceholder={!!placeholder}
        rtl={rtl}
      >
        <input
          {...inputProps}
          type={getType()}
          id={inputId}
          placeholder={placeholder || 'Placeholder'}
          ref={inputRef}
          onChange={isCheckingStrength ? getScore : handleChange}
        />
        {getButton()}
        {getLink()}
        {getHelperText()}
        <StyledWrapper isPadding={isCheckingStrength}>
          {!error && assistiveText && <StyledAssistiveText>{assistiveText}</StyledAssistiveText>}
          {error && <StyledError>{error}</StyledError>}
          {isCheckingStrength && (
            <Score
              score={score}
              scoreComplexity={scoreComplexity}
              onScoreChange={onScoreChange}
            />
          )}
        </StyledWrapper>
        <label htmlFor={inputId}>
          {label}
          {hint ? <Hint text={hint} /> : null}
        </label>
      </StyledInput>
    </ThemeProvider>
  );
});

export default Input;

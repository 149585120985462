export function getInitials(str) {
  let initial = str.split(' ');

  if (initial.length > 1) {
      initial = initial.shift().charAt(0) + initial.pop().charAt(0);
  } else {
      initial = str.substring(0, 2);
  }

  return initial.toUpperCase();
};

import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import {
  parse,
  isAfter,
  isValid,
  getMonth,
  getDate,
  getYear,
  startOfQuarter,
  startOfMonth,
  lastDayOfMonth,
} from 'date-fns';
import { DateRange } from 'react-day-picker';

import {
  DAY_INPUT,
  MONTH_INPUT,
  YEAR_INPUT,
  MIN_YEAR,
  dateValidationRulesMap,
  MONTH_TO_DATE,
  QUARTER_TO_DATE,
  YEAR_TO_DATE,
  LAST_MONTH,
  LAST_QUARTER,
  LAST_YEAR,
  initialInputDate,
} from './constants';
import { InputDate } from './types';

export const handleRange = inputValue => {
  const someDateValueIsMissing = some(inputValue, isEmpty);
  const formattedDate = `${inputValue[DAY_INPUT]}/${inputValue[MONTH_INPUT]}/${inputValue[YEAR_INPUT]}`;
  const date = parse(formattedDate, 'dd/MM/yyyy', new Date());
  const isDateInValidRange = isAfter(date, MIN_YEAR);

  return { someDateValueIsMissing, isDateInValidRange, date };
};

export const getDestructuredDate = (date: number | Date): InputDate => {
  const day = ('0' + getDate(date)).slice(-2);
  const month = ('0' + (getMonth(date) + 1)).slice(-2);
  const year = String(getYear(date));

  return { day, month, year }
};

export const validateInputDate = (value: string, dateInputType: string) => {
  const { regex, length } = dateValidationRulesMap[dateInputType];

  return regex.test(value) || value.toString().length < length;
};

export const isDateValid = (isRange: boolean, date: Date | DateRange): boolean => {
  if (isRange) {
    const range = date as unknown as DateRange;
    const hasBothEnds = range?.from && range?.to;
    const datesAreValid = isValid(range?.from) && isValid(range?.to);

    return hasBothEnds && datesAreValid;
  }

  const datesIsValid = isValid(date);

  return datesIsValid;
}

export const getDateForSelectedRange = (id: string): DateRange => {
  switch (id) {
    case MONTH_TO_DATE:
      return getMonthToDateRange();
    case QUARTER_TO_DATE:
      return getQuarterToDateRange();
    case YEAR_TO_DATE:
      return getYearToDateRange();
    case LAST_MONTH:
      return getLastMonthRange();
    case LAST_QUARTER:
      return getLastQuarterRange();
    case LAST_YEAR:
      return getLastYearRange();
    default:
      return undefined;
  }
};

export const getHandlerValuesFromDefaultDate = (isRange, value) => {
  if (!isRange && !(value as DateRange)?.from && !(value as DateRange).to) {
    return {
      from: getDestructuredDate(value as Date)
    }
  }

  if (isRange) {
    return {
      from: (value as DateRange)?.from ? getDestructuredDate((value as DateRange).from) : initialInputDate,
      to: (value as DateRange)?.to ? getDestructuredDate((value as DateRange).to) : initialInputDate,
    }
  }
};

const getMonthToDateRange = () => {
  const currentMonth = (new Date()).getMonth() + 1;
  const currentYear = (new Date()).getFullYear();
  const monthToDateRange = { from: new Date(`${currentYear}, ${currentMonth}, 1`), to: new Date() };

  return monthToDateRange;
};

const getQuarterToDateRange = () => {
  const startOfCurrentQuarter = startOfQuarter(new Date());
  const quarterToDate = { from: startOfCurrentQuarter, to: new Date() };

  return quarterToDate;
};

const getYearToDateRange = () => {
  const currentYear = (new Date()).getFullYear();
  const yearToDateRange = { from: new Date(`${currentYear}, 1, 1`), to: new Date() };

  return yearToDateRange;
};

const getLastMonthRange = () => {
  const currentMonth = new Date();
  currentMonth.setDate(0);
  const fromStartDate = startOfMonth(currentMonth);
  const lastDayOfLastMonth = lastDayOfMonth(fromStartDate);
  const lastMonthRange = { from: fromStartDate, to: lastDayOfLastMonth };

  return lastMonthRange;
};

const getLastQuarterRange = () => {
  const lastDayOfPrevQuarter = new Date(startOfQuarter(new Date()).setDate(startOfQuarter(new Date()).getDate() - 1));
  const lastDayOfLastMonth = lastDayOfMonth(lastDayOfPrevQuarter);
  const startOfPrevQuarter = startOfQuarter(lastDayOfPrevQuarter);

  const lastQuarterRange = { from: startOfPrevQuarter, to: lastDayOfLastMonth };

  return lastQuarterRange;
};

const getLastYearRange = () => {
  const prevYear = (new Date()).getFullYear() - 1;
  const lastDayOfLastMonth = lastDayOfMonth(new Date(`${prevYear}, 12, 1`));
  const lastYearRange = { from: new Date(`${prevYear}, 1, 1`), to: lastDayOfLastMonth };

  return lastYearRange;
};

import React, { useEffect, useRef, useState, ReactNode } from 'react';
import dayjs from 'dayjs';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';

import { DatePickerProps } from 'react-date-picker';

import S from './styles';

export interface Props extends DatePickerProps {
  isOpen?: boolean;
  onCalendarClose?: () => void;
  onCalendarOpen?: () => void;
  placeholder?: string;
  className?: string;
  value?: Date | undefined;
  singleView?: boolean;
  input?: string | ReactNode;
}

function DatePicker(props: Props) {
  const {
    calendarIcon = null,
    clearIcon = <S.ClearIcon />,
    defaultView = 'month',
    format = 'dd/MM/yyyy',
    isOpen: isPropsOpen,
    maxDetail = 'month',
    minDetail = 'month',
    onCalendarClose,
    onCalendarOpen,
    placeholder = 'Date',
    value,
    className,
    input,
  } = props;
  const [isOpen, setIsOpen] = useState(isPropsOpen ?? false);
  const ref = useRef(null);
  const inputRef = useRef(null);
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  const parsedValue = dayjs(value).format('MMM D');

  useEffect(() => {
    const { wrapper } = ref.current;
    const calendarInput = wrapper.firstChild;
    const calendarPopupWrapper = inputRef.current;
    const additionalParent = document.createElement('div');
    additionalParent.classList.add('react-date-picker-container');
    additionalParent.appendChild(calendarInput);
    calendarPopupWrapper.insertBefore(additionalParent, calendarPopupWrapper.firstChild);
    calendarInput.classList.add('is-visible');
  }, []);

  function openCalendar(e) {
    e.preventDefault();
    setIsOpen(true);

    if (typeof onCalendarOpen === 'function') {
      onCalendarClose();
    }
  }

  function closeCalendar() {
    setIsOpen(false);

    if (typeof onCalendarClose === 'function') {
      onCalendarClose();
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div>
          <S.DateCustomWrapper onClick={openCalendar} className={className}>
            {input ? (
              input
            ) : (
              <>
                <S.CalendarIcon />
                <S.DateWrapper>{value ? `${parsedValue}` : placeholder}</S.DateWrapper>
              </>
            )}
          </S.DateCustomWrapper>

        <S.DateComponent
          {...props}
          calendarIcon={calendarIcon}
          clearIcon={clearIcon}
          defaultView={defaultView}
          format={format}
          isOpen={isOpen}
          maxDetail={maxDetail}
          minDetail={minDetail}
          onCalendarClose={closeCalendar}
          inputRef={inputRef}
          ref={ref}
        />
      </div>
    </ThemeProvider>
  );
}

export default DatePicker;

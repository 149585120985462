import styled, { DefaultTheme } from 'styled-components';

import { fade, darken, opaque } from 'utils/colorTransform';
import { BUTTON_SIZES_HEIGHT, BUTTON_SIZES_PADDING } from './constants';
import { hex2rgba } from 'utils/style-helpers';
import { ButtonProps, ButtonCategory, ButtonSize } from './types';

interface Props extends ButtonProps {
  theme: DefaultTheme;
}

export function getBackgroundColor(props: Props) {
  const {
    category,
    theme: { colors },
    isDanger,
    isActive,
    disabled
  } = props;

  switch (category) {
    case 'secondary':
    case 'link':
      return 'transparent';
    case 'switch':
      return disabled
        ? isActive ? colors.greyBright : 'transparent'
        : isActive ? hex2rgba(colors.green, 0.1) : 'transparent';
    default:
      return isDanger ? colors.red : colors.primary;
  }
}

export function getHoverBackgroundColor(props: Props) {
  const {
    category,
    theme: {
      colors,
      button: { hoverColor },
    },
    isDanger,
    isActive
  } = props;

  switch (category) {
    case 'secondary':
    case 'link':
      return 'transparent';
    case 'switch':
      return isActive ? hex2rgba(colors.green, 0.1) : 'transparent';
    default:
      return isDanger ? fade(colors.red) : hoverColor;
  }
}

export function getActiveBackgroundColor(props: Props) {
  const {
    category,
    theme: { colors },
    isDanger,
    isActive
  } = props;

  switch (category) {
    case 'secondary':
    case 'link':
      return 'transparent';
    case 'switch':
      return isActive ? hex2rgba(colors.green, 0.1) : 'transparent';
    default:
      return isDanger ? darken(colors.red) : colors.greyDarkest;
  }
}

export function getTextColor(props: Props) {
  const {
    category,
    theme: {
      colors,
      button: { textColor },
    },
    isDanger,
  } = props;

  switch (category) {
    case 'secondary':
      return isDanger ? colors.red : colors.primary;
    case 'link':
      return isDanger ? colors.red : colors.link;
    case 'switch':
      return colors.black;
    default:
      return isDanger ? colors.white : textColor;
  }
}

export function getHoverTextColor(props: Props) {
  const {
    category,
    theme: { colors, button },
    isDanger,
  } = props;

  switch (category) {
    case 'secondary':
      return isDanger ? fade(colors.red) : button.hoverColor;
    case 'link':
      return isDanger ? fade(colors.red) : fade(colors.link);
    case 'switch':
      return colors.black;
    default:
      return isDanger ? fade(colors.white) : fade(colors.greyLighter);
  }
}

export function getActiveTextColor(props: Props) {
  const {
    category,
    theme: { colors },
    isDanger,
  } = props;

  switch (category) {
    case 'secondary':
      return isDanger ? darken(colors.red) : colors.greyDarkest;
    case 'link':
      return isDanger ? darken(colors.red) : darken(colors.link);
    case 'switch':
      return colors.black;
    default:
      return isDanger ? darken(colors.white) : darken(colors.greyLighter);
  }
}

export function getBorder(props: Props) {
  const {
    category,
    theme: { colors },
    isDanger,
    isActive
  } = props;

  if (category === 'link' || category === 'primary') {
    return 'none';
  }


  if (category === 'switch' && isActive) {
    return `0.125rem solid transparent`
  };

  if (category === 'switch') {
    return `0.125rem solid ${colors.greyBright}`;
  }

  return `0.125rem solid ${isDanger ? colors.red : colors.primary}`;
}

export function getHeight(props: Props) {
  const { size, category } = props;

  switch (category) {
    case 'switch':
      return BUTTON_SIZES_HEIGHT.switch;
    default:
      return BUTTON_SIZES_HEIGHT[size];
  }
}

export function getPadding(props: { size?: ButtonSize, category?: ButtonCategory }) {
  const { category, size } = props;

  switch (category) {
    case 'link':
      return '0';
    case 'switch':
      return BUTTON_SIZES_PADDING.switch;
    default:
      return BUTTON_SIZES_PADDING[size];
  }
}

export function getBorderRadius(props: Props) {
  const {
    category,
    theme: { button },
  } = props;

  switch (category) {
    case 'link':
      return '0';
    case 'switch':
      return '0.25rem';
    default:
      return button.borderRadius;
  }
}

export function getFocusShadow(props: Props) {
  const {
    category,
    isDanger,
    theme: { colors },
  } = props;
  let color = colors.greyMedium;

  if (category === 'link') {
    color = colors.blue;
  }

  return `0 0 0 3px ${opaque(isDanger ? colors.red : color, 30)}`;
}

export function getFocusBorderRadius(props: Props) {
  const { category } = props;

  return category === 'link' ? '3px' : null;
}

function getOpacity(props: Props) {
  const { category, disabled, isActive } = props;

  switch (category) {
    case 'switch':
      return disabled && !isActive ? '.5' : '1';
    default:
      return disabled ? '.5' : '1';
  }
}

const StyledButton = styled.button<Props>`
  box-sizing: border-box;
  height: ${getHeight};
  background-color: ${getBackgroundColor};
  border: ${getBorder};
  font: ${(props) => props.theme.fonts.bodyMSemi};
  line-height: 1;
  color: ${getTextColor};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${getBorderRadius};
  letter-spacing: 0.02em;
  outline: none;
  padding: ${getPadding};
  transition: ${(props) => props.theme.transition};
  text-decoration: none;
  opacity: ${getOpacity};
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background-color: ${getHoverBackgroundColor};
    border-color: ${({ theme, isDanger }) => (isDanger ? fade(theme.colors!.red) : theme.button.hoverColor)};
    color: ${getHoverTextColor};

    svg {
      fill: ${getHoverTextColor};
    }
  }

  &:active {
    background-color: ${getActiveBackgroundColor};
    border-color: ${(props) => (props.isDanger ? darken(props.theme.colors!.red) : props.theme.colors!.greyDark)};
    color: ${getActiveTextColor};

    svg {
      fill: ${getActiveTextColor};
    }
  }

  &:disabled {
    pointer-events: none;
  }

  &:focus-visible {
    box-shadow: ${getFocusShadow};
    border-radius: ${getFocusBorderRadius};
  }

  svg {
    fill: ${getTextColor};
    margin-right: ${(props) => (props.label ? '0.5rem' : '')};
    transition: ${(props) => props.theme.transition};
    flex-shrink: 0;
  }

  ${(props) => ({ ...props.styles })}
`;

export default StyledButton;

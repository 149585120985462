import React, { forwardRef, PropsWithChildren, ReactNode } from 'react';
import DropdownFilterControl from '../filter-dropdown/dropdown-filter-control';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';
import S from './styles';

type LinkDropdownProps = {
  className?: string;
  label: ReactNode;
  openToLeft?: boolean;
  hideArrow?: boolean;
};

const LinkDropdown = forwardRef<any, PropsWithChildren<LinkDropdownProps>>(
  ({ className, children, label, hideArrow = true, openToLeft = false }, parentRef) => {
    const parentTheme = useTheme();
    const theme = handleTheme(parentTheme);

    return (
      <ThemeProvider theme={theme}>
        <DropdownFilterControl
          className={className}
          hideArrow={hideArrow}
          label={label}
          openToLeft={openToLeft}
          ref={parentRef}
        >
          <S.DropdownWrapper>
            <S.DropdownLabel>{children}</S.DropdownLabel>
          </S.DropdownWrapper>
        </DropdownFilterControl>
      </ThemeProvider>
    );
  },
);

export default LinkDropdown;

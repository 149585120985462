import styled, { DefaultTheme } from 'styled-components';
import { HTMLProps } from 'react';

import pnoneInputStyles from 'react-phone-input-2/lib/high-res.css';
import { searchIcon } from 'assets';

interface Props extends HTMLProps<HTMLDivElement> {
  theme: DefaultTheme;
  styles?: object;
  label: string;
  error: string;
  disabled: boolean;
}

const StyledPhoneInput = styled.div<Props>`
  position: relative;
  font: ${(props) => props.theme.fonts.bodyL};
  color: ${(props) => props.theme.colors!.black};
  opacity: ${(props) => (props.disabled ? '.5' : '1')};
  text-align: left;
  max-width: 30rem;

  ${pnoneInputStyles}

  .react-tel-input {
    color: inherit;
    font: inherit;
    background-color: ${(props) => props.theme.colors!.greyLightest};
    border-radius: 0.5rem;
    text-align: left;

    .flag-dropdown {
      border: none;
      background-color: transparent;
      width: 15%;
      max-width: 4.5rem;

      &.open {
        background-color: transparent;
      }

      &:hover .selected-flag,
      &:focus .selected-flag,
      &.open .selected-flag {
        background-color: transparent;
      }

      &:after {
        content: ${(props) => `"${props.label}"`};
        cursor: text;
        display: block;
        left: 4.3rem;
        position: absolute;
        top: 2.2rem;
        transition: ${(props) => props.theme.transition};
        white-space: nowrap;
        color: ${(props) => props.theme.colors!.greyMedium};
        font: ${(props) => props.theme.fonts.bodyXs};
        transform: translate3d(-3.3rem, -1.5rem, 0);
      }
    }

    .form-control {
      border-radius: 0.5rem;
      background-color: transparent;
      border-color: ${(props) => (props.error ? props.theme.colors!.red : props.theme.colors!.greyLightest)};
      border-width: 2px;
      padding: 2.12rem 1.125rem 0.65rem 4.5rem;
      color: inherit;
      font: inherit;
      transition: ${(props) => props.theme.transition};
      width: 100%;
      height: auto;
      -webkit-appearance: none;

      &::placeholder {
        opacity: 0;
      }

      &:enabled:hover {
        border-color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors!.greyLight)};
      }

      &:enabled:focus,
      &.open {
        border-color: ${({ error, theme: { colors } }) => (error ? colors?.error : colors?.blue)};
      }
    }

    .selected-flag {
      padding-left: 0;
      width: 100%;
      right: 0;

      &:focus {
        background-color: transparent;
      }

      .flag {
        top: 3.15rem;
        left: 1rem;
      }
    }

    .selected-flag .arrow {
      border-bottom-width: 0;
      border-color: ${(props) => props.theme.colors.black};
      border-left-width: 0;
      border-right-width: 2px;
      border-top-width: 2px;
      height: 10px;
      transform: rotate(135deg);
      width: 10px;
      top: 0.3rem;
      margin-left: 0.3rem;

      &.up {
        border-bottom: none;
        border-right: 2px solid ${(props) => props.theme.colors.black};
        border-top: 2px solid ${(props) => props.theme.colors.black};
      }
    }

    .country-list {
      box-shadow: ${(props) => props.theme.boxShadow};
      width: 666%;
      margin-top: 0.125rem;
      border-radius: 0.5rem;
      background-color: ${(props) => props.theme.colors!.white};
      padding-bottom: 1rem;

      .search {
        padding: 0.625rem 1rem;
        &:after {
          position: absolute;
          display: block;
          content: '';
          right: 1rem;
          top: 1rem;
          width: 1rem;
          height: 1rem;
          background-image: url(${searchIcon});
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      .search-box {
        border: none;
        width: 100%;
        color: ${(props) => props.theme.colors!.greyMedium};
        margin-left: 0;
        font: ${(props) => props.theme.fonts.bodyS};
        padding: 0 3rem 0 0;

        &::-webkit-search-cancel-button {
          display: none;
        }
      }

      .country,
      .no-entries-message {
        position: relative;
        padding: 0.625rem 1rem;
        margin: 0;

        &:hover {
          background-color: ${(props) => props.theme.colors!.greyLight};
        }

        .flag {
          right: 1rem;
          left: auto;
          top: 0.5rem;
        }

        .dial-code {
          position: absolute;
          right: 3rem;
        }

        * {
          color: inherit;
          font: ${(props) => props.theme.fonts.bodyS};
        }
      }
    }
  }

  ${(props) => ({ ...props.styles })}
`;

export default StyledPhoneInput;

import styled, { DefaultTheme } from 'styled-components';

interface Props {
  theme: DefaultTheme;
  color?: string;
  styles?: object;
}

export const StyledError = styled.span<Props>`
  font: ${(props) => props.theme.fonts.bodyXs};
  line-height: 1.05;
  padding-left: 1rem;
  color: ${(props) => props.color || props.theme.colors.red};
  margin-top: 0.125rem;

  ${(props) => ({ ...props.styles })}
`;

export const StyledAssistiveText = styled.span<Props>`
  font: ${(props) => props.theme.fonts.bodyXs};
  line-height: 1.05;
  padding-left: 1rem;
  color: ${(props) => props.color || props.theme.colors.greyMedium};
  margin-top: 0.125rem;

  ${(props) => ({ ...props.styles })}
`;

import React, { InputHTMLAttributes, useState } from 'react';
import { SearchIcon, Wrapper, SearchInput, SearchCloseIcon } from './styles';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';

export interface SearchProps extends InputHTMLAttributes<HTMLInputElement> {
  onChange: (value) => void,
  onClear?: () => void,
  placeholder?: string,
  hideClearIcon?: boolean,
  wrapperClassName?: string,
  inputClassName?: string,
};

const Search = ({
  onChange,
  onClear,
  hideClearIcon,
  wrapperClassName,
  inputClassName,
  value = '',
  placeholder = 'Search',
  ...rest
}: SearchProps) => {
  const [inputValue, setInputValue] = useState(value);
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    onChange(e.target.value);
  };

  const handleClear = () => {
    setInputValue('');
    onClear?.();
  };

  return (
    <ThemeProvider theme={theme}>
      <Wrapper className={wrapperClassName}>
        <SearchIcon />
        <SearchInput
          className={inputClassName}
          placeholder={placeholder}
          value={inputValue}
          onChange={handleChange}
          {...rest}
        />
        {!hideClearIcon && <SearchCloseIcon $isVisible={Boolean(inputValue)} onClick={handleClear} />}
      </Wrapper>
    </ThemeProvider>
  );
};

export default Search;

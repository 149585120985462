import styled, { DefaultTheme } from 'styled-components';
interface Props {
  theme: DefaultTheme;
  fontSize: string;
  styles?: object;
  children?: any;
}

const StyledParagraph = styled.p<Props>`
  font: ${(props) => props.theme.fonts.bodyM};
  color: ${(props) => props.theme.colors.intergiroBlack};
  font-size: ${(props) => props.fontSize};
  line-height: calc(${(props) => props.fontSize}*1.5);
  margin: 0;
  ${(props) => ({ ...props.styles })}
`;

export default StyledParagraph;

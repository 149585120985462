export function addClassIfExists(className?: string): string {
  return className || '';
}

export function getConditionalString(condition: any, positiveValue: string): string {
  return condition ? positiveValue : '';
}

export function addErrorClass(error?: string | boolean): string {
  return getConditionalString(Boolean(error), 'error');
}

import React, { useState, useEffect, FocusEvent, useRef, ReactNode } from 'react';
import Select, { Props as ReactSelectProps, components as reactSelectComponents } from 'react-select';
import Creatable from 'react-select/creatable';
import { StyledAssistiveText, StyledError } from 'helpers/field';
import { addErrorClass, addClassIfExists } from 'components/theme';
import Hint from 'components/hint';
import isEmpty from 'lodash/isEmpty';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';

import {
  ClearIndicator,
  DropdownIndicator,
  MultiValueRemove,
  SingleValue,
  ValueContainer
} from './components';
import { StyledHintWrap, StyledLabel, StyledSelect } from './styles';

const { NoOptionsMessage: NoOptionsMessageComponent, Option: OptionComponent } =  reactSelectComponents;

interface Props extends ReactSelectProps {
  assistiveText?: string;
  error?: string | boolean;
  hint?: string;
  label: string;
  name: string;
  styles?: object;
  value?: any;
  dropdownIcon?: Node | ReactNode;
  isMulti?: ReactSelectProps['IsMulti']
  isCreatable?: boolean;
  disableDropdown?: boolean;
}
function Dropdown(props: Props): JSX.Element {
  const {
    assistiveText,
    className,
    error,
    hint,
    isClearable = true,
    isDisabled,
    isMulti,
    label,
    name,
    onBlur,
    onFocus,
    placeholder = '',
    styles,
    value,
    dropdownIcon,
    isBeneficiaryOption,
    showClearIcon,
    isCreatable,
    disableDropdown,
  } = props;

  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  const selectRef = useRef<any>(null);
  const hasValue = !isEmpty(value);
  const [isActive, setIsActive] = useState(hasValue);

  const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const isValueEmpty = isMulti
      ? !selectRef.current?.state?.value || !selectRef.current.state.value?.length
      : !selectRef.current?.state?.value;

    if ((isValueEmpty && !hasValue) || isBeneficiaryOption) {
      setIsActive(false);
    }
    if (onBlur) {
      onBlur(event);
    }
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>): void => {
    setIsActive(true);
    if (onFocus) {
      onFocus(event);
    }
  };
  const handleLabelClick = () => {
    if (isDisabled) return;
  };

  useEffect(() => {
    if (hasValue) {
      setIsActive(true);
    }
  }, [hasValue]);

  const InputControl: typeof React.Component = isCreatable ? Creatable : Select;

  const ref = {
    ...(!isClearable && { innerRef: selectRef }),
    ...(isClearable && { ref: selectRef }),
  }

  const NoOptionsMessage = !disableDropdown ? NoOptionsMessageComponent : () => null;
  const Option = !disableDropdown ? OptionComponent : () => null;

  return (
    <ThemeProvider theme={theme}>
      <StyledSelect $rotate={!(isBeneficiaryOption || false)} styles={styles} error={error}>
        <InputControl
          {...props}
          {...ref}
          className={`${addClassIfExists(className)} ${addErrorClass(error)}`}
          classNamePrefix='select'
          components={{
            DropdownIndicator,
            ClearIndicator,
            MultiValueRemove,
            IndicatorSeparator: () => null,
            Option,
            NoOptionsMessage,
            SingleValue,
            ValueContainer,
          }}
          isDisabled={isDisabled}
          isMulti={isMulti}
          name={name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          placeholder={placeholder}
          dropdownIcon={dropdownIcon}
          isBeneficiaryOption={isBeneficiaryOption}
          controlShouldRenderValue={!isBeneficiaryOption}
          openMenuOnFocus={isClearable}
          isClearable={showClearIcon || isClearable}
        />
        <StyledLabel
          hasPlaceholder={Boolean(placeholder)}
          isActive={isActive}
          isDisabled={isDisabled}
          onClick={handleLabelClick}
          isBeneficiaryOption={isBeneficiaryOption}
          className={`dropdown-label ${isActive ? 'selected__input-has-value' : ''}`}
        >
          {label}
          {hint ? <StyledHintWrap><Hint text={hint} /></StyledHintWrap> : null}
        </StyledLabel>
        {!error && assistiveText && <StyledAssistiveText>{assistiveText}</StyledAssistiveText>}
        {error && typeof error === 'string' && <StyledError>{error}</StyledError>}
      </StyledSelect>
    </ThemeProvider>
  );
}

export { CurrencyOption } from './components';
export { BeneficiaryOption } from './components';
export { FilterDropdown } from './components';
export { LinkDropdown } from './components';
export { ActionDropdown } from './components'
export default Dropdown;

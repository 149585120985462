import React from 'react';
import { components } from 'react-select';

import { ChevronBottom } from 'assets';

import { MagnifyingGlass } from '../styles';

const DropdownIndicator = (props) => {
  const { isBeneficiaryOption, disableDropdown } = props?.selectProps;

  if (disableDropdown) {
    return null;
  }

  return (
    <components.DropdownIndicator {...props}>
      {isBeneficiaryOption ? (
        <MagnifyingGlass />
      ) : (
        <ChevronBottom />
      )}
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;

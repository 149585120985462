const permissionStates = {
  granted: 'granted',
  prompt: 'prompt',
  denied: 'denied'
};

const permissionName = 'clipboard-write' as PermissionName;

export async function copyClipboardApi(copyContent: string) {
  try {
    const permission = await navigator.permissions.query({ name: permissionName })

    if (permission.state === permissionStates.granted || permission.state === permissionStates.prompt) {
      navigator.clipboard.writeText(copyContent)
    }
  } catch {
    copyFallback(copyContent)
  }
}

export function copyFallback(copyContent: string) {
  const fakeElement = document.createElement('span')
  fakeElement.textContent = copyContent

  fakeElement.style.whiteSpace = 'pre'
  fakeElement.style.webkitUserSelect = 'auto'
  fakeElement.style.userSelect = 'all'
  fakeElement.style.fontSize = '0'

  document.body.appendChild(fakeElement)
  const selection = window.getSelection()
  const range = document.createRange()
  selection.removeAllRanges()
  range.selectNode(fakeElement)
  selection.addRange(range)

  try {
    document.execCommand('copy')
  } finally {
    selection.removeAllRanges()
    document.body.removeChild(fakeElement)
  }
}

import React from 'react';
import { ThemeProvider, useTheme } from 'styled-components';
import AvatarBackground from 'boring-avatars';
import ColorHash from 'color-hash';

import { handleTheme } from 'utils/themeHelper';
import { AvatarWrapper, AvatarName } from './styles';
import { getInitials } from './utils'

export interface Props  {
    name: string;
    param1?: string;
    param2?: string;
    small?: boolean;
    className?: string;
}

function Avatar({name, param1, param2, small, className}: Props): JSX.Element {
    const colorHash = new ColorHash();
    const nameHex = colorHash.hex(name || '');
    const param1Hex = colorHash.hex(param1 || '');
    const param2Hex = colorHash.hex(param2 || '');
    const initials = getInitials(name || '');

    const parentTheme = useTheme();
    const theme = handleTheme(parentTheme);

    return (
        <ThemeProvider theme={theme}>
            <AvatarWrapper className={className}>
                <AvatarBackground
                    size={small ? 32 : 56}
                    name={name}
                    variant='marble'
                    colors={[nameHex, param1Hex, param2Hex]}
                />
                <AvatarName small={small}>{initials}</AvatarName>
            </AvatarWrapper>
        </ThemeProvider>
    );
}

export default Avatar;

import styled from 'styled-components';

type DateInputContainerProps = {
  error: boolean;
};

const DateInputContainer = styled.div<DateInputContainerProps>`
  color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.greyBright};
  border-radius: 0.5rem;
  border: ${(props) => `0.0625rem solid ${props.error ? props.theme.colors.red : props.theme.colors.greyBright}`};

  border-color: transparent;
  border-style: solid;
  border-width: 0.125rem;
  box-sizing: border-box;
  transition: all 200ms cubic-bezier(0.645,0.045,0.355,1);
`;

const DateInput = styled.input`
  background: none;
  border: 0;
  font: ${(props) => props.theme.fonts.bodySSemi};
  color: ${(props) => props.theme.colors.black};
  padding: 0;
  box-sizing: content-box;
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 0.0625rem;
  text-align: center;

  &:focus-visible {
    outline: none;
  }
`;

export default {
  DateInputContainer,
  DateInput,
};

import styled, { DefaultTheme } from 'styled-components';

import { activeStateIcon, inactiveStateIcon } from './icons';

interface LabelProps {
  theme: DefaultTheme;
  label?: string;
}

const Label = styled.label<LabelProps>`
  display: flex;
  align-items: center;
  gap: ${props => props.label ? '1.25rem' : '0'};
  font: ${props => props.theme.fonts.bodyL};
  color: ${props => props.theme.colors.black};
  cursor: pointer;
`;

const Switch = styled.div`
  position: relative;
  width: 3rem;
  height: 2rem;
  background: ${props => props.theme.colors.greyBright};
  border-radius: 0.5rem;
  transition: 150ms all;

  &:before {
    content: "";
    position: absolute;
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0.12rem;
    border-radius: 0.5rem;
    top: 50%;
    background: ${props => props.theme.colors.greyMediumDark};
    background-image: url('${inactiveStateIcon}');
    background-position: center center;
    background-repeat: no-repeat;
    transition: 150ms all;
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    &:before {
      background: ${props => props.theme.colors.black};
      background-image: url('${activeStateIcon}');
      background-position: center center;
      background-repeat: no-repeat;
      transform: translate(1rem, -50%);
    }
  }
`;

export default {
  Label,
  Input,
  Switch,
};

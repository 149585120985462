import styled, { DefaultTheme } from 'styled-components';

interface ActionButtonContainerProps {
  theme: DefaultTheme;
  disabled?: boolean;
  selected?: boolean;
}

interface ActionButtonProps {
  theme: DefaultTheme;
  disabled?: boolean;
  selected?: boolean;
}

interface IconProps {
  theme: DefaultTheme;
  selected?: boolean;
}

const ActionButton = styled.div<ActionButtonProps>`
  width: 3rem;
  height: 3rem;
  background: ${props => props.selected ? props.theme.colors!.greyMedium : props.theme.colors!.greyLight};
  border-radius: 3rem;
  position: relative;
`;

const ActionButtonContainer = styled.div<ActionButtonContainerProps>`
  display: flex;
  flex-direction: column;
  width: 3rem;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? 0.4 : 1};

  &:hover {
    ${ActionButton} {
      background: ${props => props.theme.colors!.greyLight};
    }
  }
`;

const Icon = styled.i<IconProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  path {
    fill: ${props => props.selected ? props.theme.colors!.white : props.theme.colors!.black};
  }
`;

const Label = styled.p`
  font: ${props => props.theme.fonts.bodyXxs};
  line-height: 1rem;
  letter-spacing: 0.01em;
  text-align: center;
  padding-top: 0.1875rem;
  margin: 0;
`;

export default { ActionButton, Icon, ActionButtonContainer, Label };

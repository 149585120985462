import React, { Children, Fragment, PropsWithChildren, useEffect, useState } from 'react';
import get from 'lodash/get';
import { updateQuery, getStartPageQueryObject, getQueryObject } from './helpers';
import PaginationItem from './pagination-item';
import { PaginationProps } from './types';
import isEqual from 'lodash/isEqual';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';

function Pagination({
  children,
  className,
  enableScrollReset = false,
  firstItemId,
  history,
  isSticky = false,
  lastItemId,
  location,
  paginationMeta,
  cleanQueryTriggers,
}: PropsWithChildren<PaginationProps>) {
  const [cleanQueryCache, setCleanQueryCache] = useState({});
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  useEffect(() => {
    const query = getQueryObject(location);
    const cleanQuery = getStartPageQueryObject(location);
    const shouldResetSearchParameters =
      get(query, 'meta.endingBefore') &&
      !paginationMeta.hasMore &&
      Children.count(children) < Number(paginationMeta.limit);

    if (shouldResetSearchParameters || !firstItemId || !lastItemId) {
      updateQuery(history, location, { meta: {} }, cleanQuery);
    }
  }, [firstItemId, lastItemId]);

  useEffect(() => {
    if (!isEqual(cleanQueryCache, cleanQueryTriggers)) {
      const cleanQuery = getStartPageQueryObject(location);
      updateQuery(history, location, { meta: {} }, cleanQuery);
      setCleanQueryCache(cleanQueryTriggers);
    }
  }, [cleanQueryTriggers]);

  const renderPageItem = (additionalProps?) => (
    <PaginationItem
      paginationMeta={paginationMeta}
      firstItemId={firstItemId}
      lastItemId={lastItemId}
      location={location}
      history={history}
      className={className}
      {...additionalProps}
    />
  );

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        {renderPageItem({ enableScrollReset, isSticky })}
        {children}
      </Fragment>
    </ThemeProvider>
  );
}

export default Pagination;

import has from 'lodash/has';
import { FilterDropdown } from 'components/dropdown';
import React, { useState } from 'react';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';
import { updateQuery, getStartPageQueryObject, getQueryObject } from './helpers';
import {
  DoubleChevronIcon,
  NumberOfRows,
  PaginationButton,
  PaginationContainer,
  PaginationWrapper,
  RowsPerPageContainer,
  RowsPerPageLabel,
  SingleChevronIcon,
} from './styles';

import { MetaType } from './types';

const limitOptions = [
  { label: '10', value: '10', checked: false, id: '10' },
  { label: '20', value: '20', checked: false, id: '20' },
  { label: '30', value: '30', checked: false, id: '30' },
  { label: '50', value: '50', checked: false, id: '50' },
  { label: '100', value: '100', checked: false, id: '100' },
];
const directionMap = {
  next: 'next',
  prev: 'prev',
  reset: 'reset',
};

function getInitialList(limit: string) {
  return limitOptions.map((element) => ({ ...element, checked: String(limit) === String(element.value) }));
}

function PaginationItem({
  enableScrollReset,
  firstItemId,
  history,
  isSticky = false,
  lastItemId,
  location,
  paginationMeta,
  className,
}) {
  const { hasMore, numberOfRecords, limit = '10', sortBy, sortType } = paginationMeta;

  const [list, setList] = useState(getInitialList(limit));
  const activeLabel = list.find((element) => element.checked)?.label || 'Default';
  const currentQuery = getQueryObject(location);
  const meta = currentQuery.meta || {};

  const direction = getDirection(meta);
  const prevPageExists = checkPrevPageExists(direction, hasMore);
  const nextPageExists = checkNextPageExists(direction, hasMore);
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  function onChangeLimit(event) {
    const metaType = {
      limit: event.target.value,
      sortBy,
      sortType,
    };
    const selectedId = event.target.id;
    const activeList = list.map((element) => ({ ...element, checked: element.id === selectedId }));
    setList(activeList);

    resetScrollIfEnabled();
    updateQuery(history, location, { meta: metaType });
  }

  function handlePageUpdate(newDirection) {
    return function () {
      const cleanQuery = getStartPageQueryObject(location);
      const metaType: MetaType = {};

      if (newDirection === directionMap.next) {
        metaType.startingAfter = lastItemId;
      } else if (newDirection === directionMap.prev) {
        metaType.endingBefore = firstItemId;
      }

      resetScrollIfEnabled();
      updateQuery(history, location, { meta: metaType }, cleanQuery);
    };
  }

  function resetScrollIfEnabled() {
    if (!enableScrollReset) {
      return;
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <PaginationContainer isSticky={isSticky} className={className}>
        <PaginationWrapper>
          <div>
            <PaginationButton
              aria-label='First page'
              disabled={direction === directionMap.reset}
              onClick={handlePageUpdate(directionMap.reset)}
              type='button'
            >
              <DoubleChevronIcon $isDisabled={direction === directionMap.reset} />
            </PaginationButton>

            <PaginationButton
              aria-label='Previous page'
              disabled={!prevPageExists}
              onClick={handlePageUpdate(directionMap.prev)}
              type='button'
            >
              <SingleChevronIcon $isDisabled={!prevPageExists} />
            </PaginationButton>
            <PaginationButton
              aria-label='Next page'
              disabled={!nextPageExists}
              onClick={handlePageUpdate(directionMap.next)}
              type='button'
            >
              <SingleChevronIcon
                $isDisabled={!nextPageExists}
                $isRotated={true}
              />
            </PaginationButton>
          </div>
          <NumberOfRows>{numberOfRecords} results</NumberOfRows>
        </PaginationWrapper>
        <RowsPerPageContainer>
          <RowsPerPageLabel>Rows per page</RowsPerPageLabel>
          <FilterDropdown onChange={onChangeLimit} list={list} name='page-size' label={activeLabel} />
        </RowsPerPageContainer>
      </PaginationContainer>
    </ThemeProvider>
  );
}

export default PaginationItem;

// Private functions
function getDirection(meta) {
  switch (true) {
    case has(meta, 'startingAfter'):
      return directionMap.next;
    case has(meta, 'endingBefore'):
      return directionMap.prev;
    default:
      return directionMap.reset;
  }
}

function checkPrevPageExists(direction, hasMore) {
  switch (true) {
    case direction === directionMap.next:
    case direction === directionMap.prev && hasMore:
      return true;
    case direction === directionMap.reset:
    default:
      return false;
  }
}

function checkNextPageExists(direction, hasMore) {
  switch (true) {
    case direction === directionMap.prev:
    case [directionMap.next, directionMap.reset].includes(direction) && hasMore:
      return true;
    default:
      return false;
  }
}

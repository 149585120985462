import React from 'react';
import { components } from 'react-select';

import { CrossIcon } from 'assets';

const ClearIndicator = (props) => {
  const isBeneficiaryOption = props?.selectProps?.isBeneficiaryOption;

  return !isBeneficiaryOption && (
    <components.ClearIndicator {...props}>
      <CrossIcon />
    </components.ClearIndicator>
  );
};

export default ClearIndicator;

import React, { useRef, useState, useEffect } from 'react';
import { ActionDropdownProps } from './types';
import { generateUniqueId } from 'utils/generateUniqueId';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';

import S from './styles';

const ActionDropdown = ({
  children,
  items = [],
  header,
  openToLeft,
  openToTop,
  maxHeight,
  disabled,
  containerClassName,
  onDropdownToggle
}: ActionDropdownProps) => {
  const [visible, setVisibility] = useState(false);
  const ref = useRef(null);
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  function closeDropdown() {
    setVisibility(false);
    onDropdownToggle(false);
  }

  function handleDocumentClick(event: MouseEvent) {
    if (ref.current.contains(event.target)) {
      return;
    }

    setVisibility(false);
    onDropdownToggle(false);
  }

  function handleDropdownClick() {
    if (disabled) return;

    setVisibility(!visible);
    onDropdownToggle?.(!visible);
  }

  return (
    <ThemeProvider theme={theme}>
      <S.Container ref={ref} className={containerClassName}>
        <S.DropdownTrigger disabled={disabled} onClick={handleDropdownClick}>{children}</S.DropdownTrigger>
        <S.DropdownWrapper $visible={visible} openToLeft={openToLeft} openToTop={openToTop} maxHeight={maxHeight}>
          {header && (
            <S.DropdownHeader>
                {header}
            </S.DropdownHeader>
          )}
          {items.map(item =>
            <S.DropdownItem key={generateUniqueId()} onClick={closeDropdown}>{item}</S.DropdownItem>)}
        </S.DropdownWrapper>
      </S.Container>
    </ThemeProvider>
  )
}

export default ActionDropdown;

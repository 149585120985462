import React, { ChangeEvent } from 'react';

import {
  DAY_INPUT,
  MONTH_INPUT,
  YEAR_INPUT,
  DEFAULT_INPUT,
} from '../constants'
import { DateInputTypes } from '../types';
import S from './styles';

const DateInput = ({ className, values, label, onChange, onClick, onDateInputFocus, error }: DateInputTypes) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>, dateInputType: string) => {
    onChange(e, dateInputType);
  }

  return (
    <S.DateInputContainer className={className} onClick={onClick} error={true}>
      <S.DateInput
        value={values[DAY_INPUT]}
        onChange={e => handleChange(e, DAY_INPUT)}
        size={2}
        placeholder={`${DEFAULT_INPUT}`}
        onBlur={onDateInputFocus()}
      />
      /
      <S.DateInput
        value={values[MONTH_INPUT]}
        size={2}
        onChange={e => handleChange(e, MONTH_INPUT)}
        placeholder={`${DEFAULT_INPUT}`}
        onBlur={onDateInputFocus()}
      />
      /
      <S.DateInput
        value={values[YEAR_INPUT]}
        size={4}
        onChange={e => handleChange(e, YEAR_INPUT)}
        placeholder={`${DEFAULT_INPUT}${DEFAULT_INPUT}`}
        onBlur={onDateInputFocus()}
      />
      {label && (
        <label>{label}</label>
      )}
    </S.DateInputContainer>
  );
};

export default DateInput;

import { DefaultTheme } from 'styled-components';

const black = '#111111';
const red = '#EB5938';
const greyMedium = '#75757F';
const greyLighter = '#F9F9F9'

const legacyFonts = {
  text: '400 1.125rem/1.22 Gellix, Source Sans Pro, sans-serif',
  title: '600 2.625rem/1.2 Gellix, Source Sans Pro, sans-serif',
  controlText: '400 1.25rem/1.22 Gellix, Source Sans Pro, sans-serif',
};

const legacyColors = {
  link: greyMedium,
  text: '#000000',
  error: red,
  primary: black,
}

const theme: DefaultTheme = {
  colors: {
    black,
    blue: '#455AA2',
    cream: '#F6E3C6',
    green: '#00A673',
    yellow: '#FFDA44',
    orange: '#F79E1B',
    red,
    greyDark: '#3E3C39',
    greyMedium,
    greyMediumDark: '#B2B2B9',
    greyBright: '#F3F4F5',
    greyDarkest: '#403C39',
    greyLightest: '#F6F7F8',
    greyLight: '#D6D6DA',
    greyLighter,
    intergiroBlack: '#000000',
    white: '#ffffff',
    ...legacyColors,
  },
  fonts: {
    ...legacyFonts,
    h1: '400 2.625rem/1.2 Gellix, Source Sans Pro, sans-serif',
    h1Semi: '500 2.625rem/1.2 Gellix, Source Sans Pro, sans-serif',
    h1Bold: '600 2.625rem/1.2 Gellix, Source Sans Pro, sans-serif',
    h2: '400 2.25rem/1.2 Gellix, Source Sans Pro, sans-serif',
    h2Semi: '500 2.25rem/1.2 Gellix, Source Sans Pro, sans-serif',
    h2Bold: '600 2.25rem/1.2 Gellix, Source Sans Pro, sans-serif',
    h3: '400 1.625rem/1.2 Gellix, Source Sans Pro, sans-serif',
    h3Semi: '500 1.625rem/1.2 Gellix, Source Sans Pro, sans-serif',
    h3Bold: '600 1.625rem/1.2 Gellix, Source Sans Pro, sans-serif',
    h4: '400 1.5rem/1.2 Gellix, Source Sans Pro, sans-serif',
    h4Semi: '500 1.5rem/1.2 Gellix, Source Sans Pro, sans-serif',
    h4Bold: '600 1.5rem/1.2 Gellix, Source Sans Pro, sans-serif',
    bodyL: '400 1.25rem/1.2 Gellix, Source Sans Pro, sans-serif',
    bodyLSemi: '500 1.25rem/1.2 Gellix, Source Sans Pro, sans-serif',
    bodyLBold: '600 1.25rem/1.2 Gellix, Source Sans Pro, sans-serif',
    bodyM: '400 1.125rem/1.2 Gellix, Source Sans Pro, sans-serif',
    bodyMSemi: '500 1.125rem/1.2 Gellix, Source Sans Pro, sans-serif',
    bodyMBold: '600 1.125rem/1.2 Gellix, Source Sans Pro, sans-serif',
    bodyS: '400 1rem/1.4 Gellix, Source Sans Pro, sans-serif',
    bodySSemi: '500 1rem/1.4 Gellix, Source Sans Pro, sans-serif',
    bodySBold: '600 1rem/1.4 Gellix, Source Sans Pro, sans-serif',
    bodyXs: '400 0.875rem/1.4 Gellix, Source Sans Pro, sans-serif',
    bodyXsSemi: '500 0.875rem/1.4 Gellix, Source Sans Pro, sans-serif',
    bodyXsBold: '600 0.875rem/1.4 Gellix, Source Sans Pro, sans-serif',
    bodyXxs: '400 0.75rem/1.3 Gellix, Source Sans Pro, sans-serif',
    bodyXxsSemi: '500 0.75rem/1.3 Gellix, Source Sans Pro, sans-serif',
    bodyXxsBold: '600 0.75rem/1.3 Gellix, Source Sans Pro, sans-serif',
    bodyXxxs: '400 0.625rem/1.3 Gellix, Source Sans Pro, sans-serif',
    bodyXxxsSemi: '500 0.625rem/1.3 Gellix, Source Sans Pro, sans-serif',
    bodyXxxsBold: '600 0.625rem/1.3 Gellix, Source Sans Pro, sans-serif',
  },
  boxShadow: '0px 0px 8px rgba(117, 117, 127, 0.25)',
  transition: 'all 200ms cubic-bezier(0.645, 0.045, 0.355, 1)',
  button: {
    borderRadius: '1.875rem',
    hoverColor: greyMedium,
    textColor: greyLighter,
  },
};

export default theme;
export * from './utils';

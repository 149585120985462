import { TickIcon } from 'assets';
import { ChevronDownShorter } from 'assets';

import styled, { css } from 'styled-components';
import { hex2rgba } from 'utils/style-helpers';

const DropdownWrapper = styled.div`
  border-radius: 0.375rem;
  box-shadow: 0 0.125rem 1.5rem rgba(62, 60, 57, 0.1), inset 0 0 0.0625rem rgba(62, 60, 57, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.25rem;
  padding: 0.5rem 0.375rem;
`;

const DropdownLabel = styled.label`
  background-color: ${(props) => props.theme.colors!.white};
  color: ${(props) => props.theme.colors!.black};
  display: block;
  font: ${(props) => props.theme.fonts.bodySSemi};
  margin-bottom: 0.25rem;
  min-width: 6.25rem;
  padding: 0.5rem 0.625rem;
  position: relative;
  text-align: left;

  :focus,
  :active,
  :focus-within {
    background-color: ${(props) => props.theme.colors!.greyLight};
  }
`;

const DropdownCheckboxWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: max-content;
`;

const DropdownCheckbox = styled.input`
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  opacity: 0;
  width: 100%;
`;

const DropdownActiveIndicator = styled(TickIcon)`
  fill: ${(props) => props.theme.colors!.blue};
  margin-left: 0.625rem;
`;

const Bar = styled.div`
  display: inline-flex;
  position: relative;
`;

const Container = styled.div<{ $visible: boolean }>`
  background-color: ${(props) => props.theme.colors!.white};
  border-radius: 0.375rem;
  display: ${(props) => (props.$visible ? 'flex' : 'none')};
  flex-direction: column;
  position: relative;
`;

const FilterBarButton = styled.button`
  align-items: center;
  background: transparent;
  border-radius: 0.1875rem;
  border: none;
  color: ${(props) => props.theme.colors!.blue};
  cursor: pointer;
  display: flex;
  font: ${(props) => props.theme.fonts.bodyMSemi};
  padding: 0;

  &:focus,
  &:hover,
  &:focus-within {
    background-color: ${(props) => hex2rgba(props.theme.colors!.blue, 0.08)};
  }
`;

const Wrapper = styled.div<{ openToLeft?: boolean }>`
  background-color: ${(props) => props.theme.colors!.white};
  border-radius: 0.375rem;
  right: 0;
  position: absolute;
  top: 100%;
  z-index: 1;

  ${(props) =>
    props.openToLeft &&
    css`
      right: initial;
      left: 0;
    `}
`;

const ArrowDown = styled(ChevronDownShorter) <{ $isVisible: boolean }>`
  fill: ${(props) => props.theme.colors!.blue};
  margin-left: 0.375rem;
  transform: ${(props) => (props.$isVisible ? 'rotate(180deg)' : '')};
`;

export default {
  ArrowDown,
  Bar,
  Container,
  DropdownActiveIndicator,
  DropdownCheckbox,
  DropdownCheckboxWrapper,
  DropdownLabel,
  DropdownWrapper,
  FilterBarButton,
  Wrapper,
};

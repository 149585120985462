
import styled, { keyframes } from 'styled-components';
import DatePicker from 'react-date-picker';

import { Calendar } from 'assets';
import { Close } from 'assets';

interface Props {
  ref: React.MutableRefObject<any>
}

const opacity = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const DateCustomWrapper = styled.button`
  border: none;
  background-color: ${(props) => props.theme.colors.white};
  align-items: center;
  display: flex;
`;

const DateWrapper = styled.div`
  color: ${(props) => props.theme.colors.blue};
  cursor: pointer;
  font: ${(props) => props.theme.fonts.bodyMSemi};
  margin-left: 0.5rem;
`;

const DateComponent = styled(DatePicker) <Props>`
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    align-items: center;
    border: none;
    color: transparent;
    display: flex;
    height: 2rem;
    justify-content: center;
    position: relative;
    width: 2rem;

    ::after {
      content: '';
      display: block;
      height: 1rem;
      width: 1.5rem;
    }
  }

  .react-calendar__navigation__prev-button::after {
    background: url("data:image/svg+xml,%3Csvg width='22' height='14' viewBox='0 0 22 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.29004 1.87006L6.23004 0.810059L0 7L6.23004 13.1901L7.29004 12.1301L2.91004 7.75006H22V6.25006H2.91004L7.29004 1.87006Z' fill='%23000'/%3E%3C/svg%3E%0A")
      no-repeat;
  }

  .react-calendar__navigation__next-button::after {
    background: url("data:image/svg+xml,%3Csvg width='22' height='14' viewBox='0 0 22 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.71 1.87006L15.77 0.810059L22 7L15.77 13.1901L14.71 12.1301L19.09 7.75006H0V6.25006H19.09L14.71 1.87006Z' fill='%233E3C39'/%3E%3C/svg%3E%0A")
      no-repeat;
  }

  .react-calendar {
    margin: 0 0.5rem;
    padding: 1rem;
    width: calc(100vw - 0.5rem);

    @media only screen and (min-width: 48rem) {
      margin: inherit;
      width: 43.75rem;
    }
  }

  .react-date-picker__calendar--open {
    animation: ${opacity} 0.3s linear;
  }

  .react-calendar__navigation__label {
    color: ${(props) => props.theme.colors!.greyMedium};
    font: ${(props) => props.theme.fonts.bodyMSemi};
  }

  .react-calendar__navigation__label[disabled] {
    background: ${(props) => props.theme.colors!.white};
  }

  .react-date-picker__wrapper {
    border: none;
    display: none;
    flex: none;
    width: 100%;

    @media only screen and (min-width: 48rem) {
      width: auto;
    }

    &.is-visible {
      display: flex;
    }
  }

  .react-calendar__tile--active,
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus,
  .react-calendar__tile--rangeStart,
  .react-calendar__tile--rangeEnd,
  .react-calendar__tile--rangeEnd.react-calendar__month-view__days__day--weekend,
  .react-calendar__tile--rangeStart.react-calendar__month-view__days__day--weekend {
    background-color: ${(props) => props.theme.colors!.black};
    color: ${(props) => props.theme.colors!.white};
  }

  .react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd {
    background-color: ${(props) => props.theme.colors!.black};
    color: ${(props) => props.theme.colors!.white};
  }

  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart,
  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd {
    background-color: ${(props) => props.theme.colors!.black};
    color: ${(props) => props.theme.colors!.white};
  }

  .react-calendar__tile--now {
    background: inherit;
  }

  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus,
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: ${(props) => props.theme.colors!.greyLight};
    color: ${(props) => props.theme.colors!.black};
  }

  .react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range,
  .react-calendar__tile--active.react-calendar__tile--range,
  .react-calendar--selectRange.react-calendar__tile--hover {
    background-color: ${(props) => props.theme.colors!.greyLight};
    color: ${(props) => props.theme.colors!.black};
  }

  .react-date-picker__calendar .react-calendar {
    box-shadow: ${(props) => props.theme.boxShadow};
    border-radius: 0.375rem;
  }

  .react-calendar__month-view__weekdays__weekday {
    font: ${(props) => props.theme.fonts.bodyXxxs};

    > abbr {
      border: none;
      text-decoration: none;
    }
  }

  .react-calendar__tile {
    border: 0.0625rem solid ${(props) => props.theme.colors!.greyLight};
    font: ${(props) => props.theme.fonts.bodyXsSemi};
  }

  .react-calendar__month-view__days__day--weekend {
    color: inherit;
  }

  .react-date-picker-container {
    align-items: center;
    border-bottom: 0.0625rem solid ${(props) => props.theme.colors!.greyLight};
    display: none;
    justify-content: flex-end;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }

  .react-date-picker__inputGroup {
    background-color: ${(props) => props.theme.colors!.greyLight};
    border-radius: 0.25rem;
    color: ${(props) => props.theme.colors!.black};
    font: ${(props) => props.theme.fonts.bodyS};
    padding: 0.625rem;
  }

`;

const CalendarIcon = styled(Calendar)`
  fill: ${(props) => props.theme.colors!.blue};
  margin-top: 0.1rem;
`;

const ClearIcon = styled(Close)`
  fill: ${(props) => props.theme.colors!.greyMedium};
`;

export default { CalendarIcon, ClearIcon, DateComponent, DateCustomWrapper, DateWrapper };

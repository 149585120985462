import rgba from 'color-rgba';

export const fade = (color: string): string => {
  const arr = rgba(color);
  const r = arr[0] + 10;
  const g = arr[1] + 10;
  const b = arr[2] + 10;

  return `rgb(${r}, ${g}, ${b})`;
};

export const darken = (color: string): string => {
  const arr = rgba(color);
  const r = arr[0] - 10;
  const g = arr[1] - 10;
  const b = arr[2] - 10;

  return `rgb(${r}, ${g}, ${b})`;
};

export const opaque = (color: string, opacity: number): string => {
  const arr = rgba(color);
  const r = arr[0];
  const g = arr[1];
  const b = arr[2];
  const a = (arr[3] * opacity) / 100;

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

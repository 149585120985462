import styled, { css } from 'styled-components';
import { DropdownWrapperProps } from './types'

const Container = styled.div`
  display: inline-flex;
  position: relative;
`;

const DropdownTrigger = styled.div<{ disabled: boolean }>`
  cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
`;

const DropdownWrapper = styled.div<DropdownWrapperProps>`
  display: ${(props) => (props.$visible ? 'flex' : 'none')};
  flex-direction: column;
  min-width: 13.25rem;
  position: absolute;
  top: calc(100% + 0.25rem);
  ${(props) =>
    props.openToTop &&
    css`
      top: initial;
      bottom: calc(100% + 0.25rem);
    `}
  right: 0;
  ${(props) =>
    props.openToLeft &&
    css`
      right: initial;
      left: 0;
    `}
  z-index: 10;
  background: ${props => props.theme.colors.white};
  border-radius: 0.375rem;
  box-shadow: ${props => props.theme.boxShadow};
  padding: 0.5rem 0;
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
      overflow: scroll;
    `}
`;

const DropdownHeader = styled.div`
  display: block;
  padding: 0 1rem 0.5rem;
  border-bottom: 0.0625rem solid ${props => props.theme.colors.greyBright};
`;

const DropdownItem = styled.div`
  cursor: pointer;
  transition: ${props => props.theme.transition};

  &:not(:last-child) {
    border-bottom: 0.0625rem solid ${props => props.theme.colors.greyBright};
  }

  &:hover {
    background: ${props => props.theme.colors.greyBright};
  }
`;

export default {
  Container,
  DropdownTrigger,
  DropdownWrapper,
  DropdownHeader,
  DropdownItem
};

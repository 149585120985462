import { LogoIcon } from 'assets';

import styled, { DefaultTheme } from 'styled-components';

interface Props {
  theme: DefaultTheme;
  className?: string;
  width?: string;
  color?: string;
  styles?: object;
}

const StyledSvg = styled(LogoIcon) <Props>`
  fill: ${(props) => props.color || props.theme.colors.intergiroBlack};
  width: ${(props) => props.width};
  ${(props) => ({ ...props.styles })}
`;

export default StyledSvg;

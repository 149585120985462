import * as React from 'react';
import S from './styles';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';
interface Props {
  size?: string;
  className?: string;
  animation?: {
    duration: string
  };
}

function AnimatedLogo({
  size = '48px',
  animation = {
    duration: '800ms',
  },
  className
}: Props): JSX.Element {
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  return (
    <ThemeProvider theme={theme}>
      <S.LogoWrapper className={className}>
        <S.Logo size={size} animation={animation} />
      </S.LogoWrapper>
    </ThemeProvider>
  );
}

export default AnimatedLogo;

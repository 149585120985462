import React, { Fragment, HTMLAttributes, ReactElement, useEffect, useRef, ReactNode } from 'react';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';

import {
  StyledControlCross,
  StyledControlsButton,
  StyledControlsContainer,
  StyledControlsWrapper,
  StyledSideBarWrapper,
  StyledSignContainer,
  StyledControlCrossWrapper,
  StyledSidebarChildrenWrapper,
  StyledArrowUp,
  StyledArrowDown,
  StyledArrowRight,
  StyledIconContainer,
} from './styles';

import useHandleOutsideClick from 'hooks/useHandleOutsideClick';

import { CrossIcon } from 'assets';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactElement;
  className?: string;
  customHeaderIcon?: ReactNode;
  disableHeaderIcon?: boolean;
  handleClose: () => void;
  handleDownArrowClick: () => void;
  handleUpArrowClick: () => void;
  isCloseOutsideEnabled?: boolean;
  isControlPanelVisible?: boolean;
  isDownArrowDisabled: boolean;
  isInternalMovement?: boolean;
  isOpen: boolean;
  isUpArrowDisabled: boolean;
}

function SideBar(props: Props): ReactElement {
  const {
    children,
    handleClose,
    handleUpArrowClick,
    handleDownArrowClick,
    isControlPanelVisible = true,
    isUpArrowDisabled,
    isOpen,
    isDownArrowDisabled,
    customHeaderIcon,
    isCloseOutsideEnabled = true,
    isInternalMovement = false,
    disableHeaderIcon = false,
    className,
    ...restProps
  } = props;
  const wrapperRef = useRef(null);
  useHandleOutsideClick(wrapperRef, handleClose, isOpen, isCloseOutsideEnabled);
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);
  function handleEscapeButtonPressed(event: KeyboardEvent) {
    if (event.key !== 'Escape') {
      return;
    }

    handleClose();
  }

  useEffect(() => {
    window.addEventListener('keydown', handleEscapeButtonPressed, { passive: true });

    return () => window.removeEventListener('keydown', handleEscapeButtonPressed);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <StyledSideBarWrapper
        className={className}
        ref={wrapperRef}
        aria-modal={Boolean(isOpen)}
        role='dialog'
        isOpen={isOpen}
        {...restProps}
      >
        {isControlPanelVisible && (
          <Fragment>
            <StyledControlsContainer>
              <StyledControlsWrapper>
                <StyledControlsButton disabled={isUpArrowDisabled} onClick={handleUpArrowClick}>
                  <StyledArrowUp />
                </StyledControlsButton>
                <StyledControlsButton disabled={isDownArrowDisabled} onClick={handleDownArrowClick}>
                  <StyledArrowDown />
                </StyledControlsButton>
              </StyledControlsWrapper>
              <StyledControlCrossWrapper>
                <StyledControlCross onClick={handleClose} aria-label='Close sidebar'>
                  <CrossIcon fill={theme.colors.black} stroke={theme.colors.black} />
                </StyledControlCross>
              </StyledControlCrossWrapper>
            </StyledControlsContainer>
            {!disableHeaderIcon && (
              <StyledIconContainer>
                <StyledSignContainer customHeaderIcon={customHeaderIcon} isInternalMovement={isInternalMovement}>
                  {customHeaderIcon ?? <StyledArrowRight />}
                </StyledSignContainer>
              </StyledIconContainer>
            )}
          </Fragment>
        )}
        <StyledSidebarChildrenWrapper className='sidebar-children-wrapper'>{children}</StyledSidebarChildrenWrapper>
      </StyledSideBarWrapper>
    </ThemeProvider>
  );
}

export default SideBar;

export const BUTTON_SIZES_HEIGHT = {
    small: '2.5rem',
    medium: '3rem',
    large: '3.375rem',
    switch: '2rem'
}

export const BUTTON_SIZES_PADDING = {
    small: '0 1rem',
    medium: '0 1.5rem',
    large: '0 4.5rem',
    switch: '0.625rem'
}

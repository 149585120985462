import React, {
  KeyboardEvent,
  MutableRefObject,
  ReactElement,
  RefObject,
  SyntheticEvent,
  useRef,
} from 'react';
import { StyledAccordionWrapper } from './styles';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';

import { AccordionContent, AccordionContentProps } from './components/accordion-content';

export interface AccordionProps {
  options: AccordionContentProps[];
  className?: string;
  onClick?: () => void;
}

function getNextStep(key: string, indexNumber: number, accordionSize: number): number {
  let nextStep;
  switch (key) {
    case 'ArrowUp':
      nextStep = indexNumber === 0 ? accordionSize : indexNumber - 1;
      break;
    case 'ArrowDown':
      nextStep = indexNumber === accordionSize ? 0 : indexNumber + 1;
      break;
    case 'Home':
      nextStep = 0;
      break;
    case 'End':
      nextStep = accordionSize;
    default:
      nextStep = 0;
      break;
  }

  return nextStep;
}

function getNodeListForAttributes(node: RefObject<HTMLDivElement>['current'], event: KeyboardEvent) {
  const nodeList: HTMLDivElement[] = Array.from(node.querySelectorAll('[data-type="accordion-button"]'));

  const { index } = (event.target as SyntheticEvent<HTMLButtonElement>['currentTarget']).dataset;

  const indexNumber = Number(index);
  const accordionSize = nodeList.length - 1;

  return {
    accordionSize,
    indexNumber,
    nodeList,
  };
}

function isKeyInTheList(key: string): boolean {
  const permittedKeys = ['Home', 'End', 'ArrowUp', 'ArrowDown'];

  return permittedKeys.includes(key);
}

function handleKeyDown(event: KeyboardEvent, ref: MutableRefObject<HTMLDivElement>): void {
  const isParentRefInjected = typeof ref.current !== 'undefined';

  if (!isKeyInTheList(event.key) || !isParentRefInjected) {
    return;
  }

  const { accordionSize, indexNumber, nodeList } = getNodeListForAttributes(ref.current, event);

  if (accordionSize === 0) {
    return;
  }

  const nextStep = getNextStep(event.key, indexNumber, accordionSize);

  nodeList[nextStep].focus();
  nodeList[indexNumber].setAttribute('tabindex', '-1');
  nodeList[nextStep].setAttribute('tabindex', '0');
}

function Accordion(props: AccordionProps): ReactElement {
  const { options, className, onClick } = props;
  const parentRef = useRef<HTMLDivElement>(null);

  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  return (
    <ThemeProvider theme={theme}>
      <StyledAccordionWrapper ref={parentRef} className={className}>
        {options.map(({ id, title, content, isActive: initialActive = true }, index) => (
          <AccordionContent
            content={content}
            handleKeyDown={(event: KeyboardEvent) => handleKeyDown(event, parentRef)}
            id={id}
            index={index}
            initialActive={initialActive}
            key={`accordion-${index}`}
            title={title}
            onClick={onClick}
          />
        ))}
      </StyledAccordionWrapper>
    </ThemeProvider>
  );
}

export default Accordion;

import React, { Children } from 'react';
import { components } from 'react-select';

function ValueContainer(props): JSX.Element {
  const { isCreatable, isMulti, onEditChange } = props.selectProps;
  const hasSingleValueChild = props.children.find(
    (child: JSX.Element) => child?.type?.name === components?.SingleValue?.name,
  );
  const hasInputChild = props.children?.find((child: JSX.Element) => child?.type?.name === components?.Input?.name);

  let updatedProps = props;
  if (isCreatable && !isMulti && hasSingleValueChild && hasInputChild && onEditChange) {
    const newChildrenArray = Children.toArray(props.children).filter((child: JSX.Element) => {
      const type = child?.type as () => void;

      return type?.name === components?.SingleValue?.name;
    });

    updatedProps = { ...props, children: newChildrenArray };
  }

  return <components.ValueContainer {...updatedProps} />;
}

export default ValueContainer;

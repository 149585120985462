import styled from 'styled-components';

import { Calendar, ChevronLeft, ArrowRight } from 'assets';
import DateInput from 'components/calendar/date-input';

type DateRangeChevronIconProps = {
  $isRotated?: boolean;
};

const HandlerInputContainer = styled.div<{ error: boolean }>`
  border: ${(props) => props.error ? `0.125rem solid ${props.theme.colors.red}` : 'none'};
  background-color: ${(props) => props.theme.colors.greyBright};
  border-radius: 0.5rem;
  align-items: center;
  display: flex;
  padding: 2.25rem 1rem 0.75rem 0.65rem;
  position: relative;
`;

const HandlerLabelContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  align-items: center;
  display: flex;
  padding: 0.25rem 0;
  box-sizing: border-box;
`;

const InputLabel = styled.span`
  position: absolute;
  color: ${(props) => props.theme.colors.greyMedium};
  top: 0.75rem;
  left: 1rem;
  font: ${(props) => props.theme.fonts.bodyXs};
`;


const CalendarIcon = styled(Calendar)`
  fill: ${(props) => props.theme.colors.blue};
  margin-top: 0.1rem;
`;

const DateRangeWrapper = styled.div`
  color: ${(props) => props.theme.colors.blue};
  cursor: pointer;
  font: ${(props) => props.theme.fonts.bodyMSemi};
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
`;

const DateRangeChevronIcon = styled(ChevronLeft) <DateRangeChevronIconProps>`
  path {
    fill: ${(props) => props.theme.colors.blue};
  }
  margin-left: 0.3906rem;
  transform: ${(props) => (props.$isRotated ? 'rotate(90deg)' : 'rotate(270deg)')};
`;

const HandlerDateInput = styled(DateInput)`
  color: ${(props) => props.theme.colors.black};
  font: ${(props) => props.theme.fonts.bodyL};
  position: relative;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  text-align: left;
  transition: ${(props) => props.theme.transition};

  label {
    color: ${(props) => props.theme.colors.greyMedium};
    cursor: text;
    font: ${(props) => props.theme.fonts.bodyXs};
    left: 1.125rem;
    position: absolute;
    right: auto;
    top: 0.75rem;
    transition: ${(props) => props.theme.transition};
  }

  input {
    font: ${(props) => props.theme.fonts.bodyL};
  }
`;

const InputHandlerSeparatorContainer = styled.div`
  padding: 0 0.25rem;
`;

const InputHandlerSeparator = styled(ArrowRight)`
  path {
    fill: ${(props) => props.theme.colors.black};
  }
`;

const ErrorMessage = styled.span`
  margin-top: 0.5rem;
  font: ${(props) => props.theme.fonts.bodyXs};
  color: ${(props) => props.theme.colors.red};
`;

export default {
  DateRangeWrapper,
  CalendarIcon,
  DateRangeChevronIcon,
  HandlerInputContainer,
  InputLabel,
  HandlerDateInput,
  InputHandlerSeparatorContainer,
  InputHandlerSeparator,
  HandlerLabelContainer,
  ErrorMessage
};

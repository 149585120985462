import styled, { DefaultTheme } from 'styled-components';
import { HTMLProps } from 'react';

interface Props extends HTMLProps<HTMLInputElement> {
  error?: string;
  hasPlaceholder?: boolean;
  isInverse?: boolean;
  rtl?: boolean;
  styles?: object;
  theme: DefaultTheme;
}

interface HelperText extends HTMLProps<HTMLInputElement> {
  theme: DefaultTheme;
  rtl?: boolean;
}

interface WrapperProps extends HTMLProps<HTMLDivElement> {
  isPadding: boolean;
}

interface LinkProps extends HTMLProps<HTMLButtonElement> {
  rtl?: boolean;
}

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  action: 'clear' | 'password' | 'none';
  rtl?: boolean;
}

export const StyledInput = styled.div<Props>`
  color: ${(props) => props.theme.colors!.black};
  position: relative;
  font: ${(props) => props.theme.fonts.bodyL};
  text-align: left;

  label {
    color: ${(props) => props.theme.colors!.greyMedium};
    cursor: text;
    font: ${(props) => props.theme.fonts.bodyS};
    left: ${(props) => (props.rtl ? 'auto' : '1.125rem')};
    position: absolute;
    right: ${(props) => (props.rtl ? '1.125rem' : 'auto')};
    top: 1.6rem;
    transition: ${(props) => props.theme.transition};
  }

  input {
    -webkit-appearance: none;
    background-color: ${(props) => props.theme.colors!.greyBright};
    border-color: ${(props) => (props.error ? props.theme.colors.red : 'transparent')};
    border-radius: 0.5rem;
    border-style: solid;
    border-width: 0.125rem;
    box-sizing: border-box;
    color: inherit;
    cursor: text;
    font: inherit;
    padding: 2rem 2.75rem 0.75rem 1rem;
    text-align: ${(props) => (props.rtl ? 'right' : 'left')};
    transition: ${(props) => props.theme.transition};
    width: 100%;
  }

  input:placeholder-shown ~ button,
  input:disabled ~ button {
    display: none;
  }

  input:not(:placeholder-shown):disabled {
    cursor: not-allowed;
    opacity: 0.5;

    & ~ label {
      opacity: 0.5;
    }
  }

  input:enabled:hover {
    border-color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors!.greyLight)};
    outline: none;
  }

  input:focus {
    border-color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors!.blue)};
    outline: none;
  }
  input:focus ~ label,
  ${(props) => (props.hasPlaceholder ? 'input ~ label' : 'input:not(:placeholder-shown) ~ label')} {
    font: ${(props) => props.theme.fonts.bodyXs};
    margin-top: -0.8rem;
  }
  input:required {
    border-color: ${(props) => props.theme.colors.red};
    color: ${(props) => props.theme.colors.intergiroBlack};
  }

  input::placeholder {
    opacity: ${(props) => (props.hasPlaceholder ? 1 : 0)};
  }

  ${(props) => ({ ...props.styles })}
`;

export const StyledWrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-right: ${(props) => (props.isPadding ? '6rem' : '')};
`;

export const StyledLink = styled.div<LinkProps>`
  position: absolute;
  left: ${(props) => (props.rtl ? '1rem' : 'auto')};
  right: ${(props) => (props.rtl ? 'auto' : '1rem')};
  top: 1.25rem;

  a {
    color: ${(props) => props.theme.colors!.blue};
    font: ${(props) => props.theme.fonts.bodyS};
    line-height: 1;
    text-decoration: none;
  }
`;

export const StyledHelperText = styled.div<HelperText>`
  position: absolute;
  top: 2.05rem;
  left: ${(props) => (props.rtl ? '1rem' : 'auto')};
  right: ${(props) => (props.rtl ? 'auto' : '1rem')};
`;

export const StyledButton = styled.button<ButtonProps>`
  background-color: transparent;
  border: none;
  top: 1.5rem;
  cursor: pointer;
  outline: none;
  padding: 0.125rem;
  position: absolute;
  left: ${(props) => props.rtl ? '1rem' : 'auto'};
  right: ${(props) => props.rtl ? 'auto' : '1rem'};
  &[action='clear'] svg {
    fill: ${(props) => props.theme.colors.greyMedium};
    width: 1rem;
    height: 1rem;
  }
`;

import styled from 'styled-components';

import { IconQuestionMark } from 'assets';
import { IconWarning } from 'assets';

export const StyledTrigger = styled.i`
  position: absolute;
  bottom: 0;
  margin-left: 0.1875rem;
`;

interface StyledTooltipProps {
  translate3d?: string;
}

export const StyledTooltip = styled.div<StyledTooltipProps>`
  background-color: ${(props) => props.theme.colors!.white};
  border-radius: 0.5rem;
  box-shadow: 0px 6px 16px rgba(17, 17, 17, 0.1);
  box-sizing: border-box;
  color: ${(props) => props.theme.colors!.black};
  font: ${(props) => props.theme.fonts.bodyXsSemi};
  letter-spacing: 0.01em;
  max-width: 13.5rem;
  min-width: 8rem;
  padding: 1rem;
  text-align: left;
  z-index: 1;
  ${props => props.translate3d ? `transform: ${props.translate3d} !important;` : ''}

  @media (max-width: 14.25rem) {
    max-width: 100%;
  }
`;

export const QuestionMarkIcon = styled(IconQuestionMark)`
  vertical-align: middle;
  path {
    fill: ${(props) => props.theme.colors!.greyMedium};
    fill-rule: evenodd;
  }
`;

export const WarningIcon = styled(IconWarning)`
  vertical-align: middle;
  path {
    fill: ${(props) => props.theme.colors!.greyMediumDark};
    fill-rule: evenodd;
  }
`;

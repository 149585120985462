
import styled from 'styled-components';

const DropdownWrapper = styled.div`
  border-radius: 0.375rem;
  box-shadow: 0 0.125rem 1.5rem rgba(62, 60, 57, 0.1), inset 0 0 0.0625rem rgba(62, 60, 57, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.25rem;
  padding: 0.5rem 0.375rem;
`;

const DropdownLabel = styled.div`
  background-color: ${(props) => props.theme.colors!.white};
  color: ${(props) => props.theme.colors!.black};
  display: block;
  font: ${(props) => props.theme.fonts.bodySSemi};
  margin-bottom: 0.25rem;
  min-width: 6.25rem;
  padding: 0.5rem 0.625rem;
  position: relative;
  text-align: left;
`;

export default { DropdownWrapper, DropdownLabel };

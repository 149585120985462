import styled, { DefaultTheme } from 'styled-components';

interface ContainerProps {
  theme: DefaultTheme;
}
interface LabelProps {
  theme: DefaultTheme;
  active: boolean;
  disabled?: boolean;
  numberOfElements: number;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  background: ${props => props.theme.colors!.greyBright};
  color: ${props => props.theme.colors!.greyMedium};
  border-radius: 0.5rem;
  width: fit-content;
  padding: 0.125rem;
  width: calc(100% - 4px);
`;

export const Label = styled.label<LabelProps>`
  padding: 0.5rem 0 0.5rem 0;
  transition: ${(props) => props.theme.transition};
  width: ${props => `${100 / props.numberOfElements}%`};
  max-width: ${props => `${100 / props.numberOfElements}%`};
  text-align: center;
  white-space: nowrap;

  ${props => props.disabled && 'opacity: 0.4;'};
  ${props => props.active && 'border-radius: 0.375rem;'};
  ${props => props.active && `background: ${props.theme.colors!.black};`};
  ${props => props.active && `color: ${props.theme.colors!.white};`};

  &:hover {
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  }
`;

export const Toggle = styled.input`
  display: none;
`;

export default { Container, Toggle, Label };


import styled, { keyframes } from 'styled-components';

interface Props {
  backgroundColor: string;
  opacity: string;
}

const fadeInOpacity = (props: Props) => keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: ${props.opacity};
	}
`;

const Wrapper = styled.div<Props>`
  position: fixed;
  z-index: 10052;
  height: 100%;
  width: 100%;
  background: ${(props) => props.backgroundColor};

  animation-name: ${fadeInOpacity};
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
	animation-duration: 150ms;
`;

export default {
  Wrapper,
}

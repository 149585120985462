import * as React from 'react';
import AnimatedLogo from 'components/animated-logo';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';
import S from './styles';

interface Props {
  backgroundColor?: string;
  className?: string;
  opacity?: string;
}

function GlobalLoader({
  backgroundColor = 'rgba(255, 255, 255, .6)',
  opacity = '0.6',
  className,
}: Props): JSX.Element {
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  return (
    <ThemeProvider theme={theme}>
      <div data-testid='global-loader'>
        <S.Wrapper backgroundColor={backgroundColor} opacity={opacity} className={className}>
          <AnimatedLogo />
        </S.Wrapper>
      </div>
    </ThemeProvider>
  );
}

export default GlobalLoader;


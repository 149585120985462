import React, { KeyboardEvent, ReactElement, ReactNode, SyntheticEvent, useState } from 'react';

import { StyledAccordionContentWrapper, StyledAccordionPanelButton } from './styles';

export interface AccordionContentProps {
  content: ReactNode;
  handleKeyDown?: (event: KeyboardEvent<HTMLButtonElement>) => void;
  id: string;
  index?: number;
  initialActive?: boolean;
  isActive?: boolean;
  title: ReactNode;
  onClick?: () => void;
}

export function AccordionContent({
  isActive,
  id,
  title,
  content,
  handleKeyDown,
  index,
  initialActive,
  onClick,
}: AccordionContentProps): ReactElement {
  const [isPanelActive, setIsPanelActive] = useState(initialActive);

  function handleClick(): void {
    if (onClick) {
      onClick();
    }

    setIsPanelActive(!isPanelActive);
  }

  return (
    <div>
      <StyledAccordionPanelButton
        aria-controls={`accordion-panel-${id}`}
        aria-expanded={isPanelActive}
        data-id={id}
        data-index={index}
        data-type='accordion-button'
        id={`accordion-button-${id}`}
        isActive={isPanelActive}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        tabIndex={isActive ? 0 : -1}
      >
        {title}
      </StyledAccordionPanelButton>

      <StyledAccordionContentWrapper
        aria-labelledby={`accordion-button-${id}`}
        id={`accordion-panel-${id}`}
        isActive={isPanelActive}
        role='region'
      >
        {content}
      </StyledAccordionContentWrapper>
    </div>
  );
}

import React, { ReactNode, RefObject, useRef, MouseEvent } from 'react';
import StyledTab from './styles';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';

function getTabPosition(tabsSize: number, tabPosition: number, keyCode: string): number | null {
  if (keyCode === 'ArrowRight') {
    return tabPosition >= tabsSize ? 0 : tabPosition + 1;
  }

  return tabPosition === 0 ? tabsSize : tabPosition - 1;
}

export interface TabProps {
  children?: ReactNode;
  handleChange: (event: MouseEvent) => void;
  id: string;
  tabPosition?: number;
  isActive?: boolean;
  name?: string;
  parentRef?: RefObject<HTMLDivElement>;
  tabsLength?: number;
  className?: string;
}

function TabHead(props: TabProps) {
  const { children, handleChange, id, tabPosition, isActive, name, parentRef, tabsLength, className } = props;

  if (!children) {
    return null;
  }
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  const childReference = useRef<HTMLButtonElement | null>(null);

  function getTabList(): NodeListOf<HTMLDivElement> | null {
    if (!parentRef) {
      return null;
    }

    return parentRef.current.querySelectorAll('[role="tab"]');
  }

  function isKeyArrow(event: KeyboardEvent): boolean {
    return event.key === 'ArrowLeft' || event.key === 'ArrowRight';
  }

  function handleKeyDown(event: KeyboardEvent): void {
    if (!isKeyArrow(event)) {
      return;
    }

    const tabs = getTabList();
    if (!tabs) {
      return;
    }
    const tabsSize = tabs.length - 1;
    const tabFocusState: number = getTabPosition(tabsSize, tabPosition as number, event.key);

    childReference.current.setAttribute('tabindex', '-1');
    tabs[tabFocusState].focus();
    tabs[tabFocusState].setAttribute('tabindex', '0');
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledTab
        className={className}
        aria-controls={`tab-panel-${(tabPosition as number) + 1}`}
        aria-posinset={(tabPosition as number) + 1}
        aria-selected={isActive}
        aria-setsize={tabsLength}
        data-testid={`tab-${id}`}
        id={`tab-label-${(tabPosition as number) + 1}`}
        isActive={isActive}
        name={name && name}
        onClick={handleChange}
        onKeyDown={handleKeyDown as any}
        ref={childReference}
        role='tab'
        tabIndex={isActive ? 0 : -1}
        value={id}
      >
        {children}
      </StyledTab>
    </ThemeProvider>
  );
}

export default TabHead;

import React from 'react';
import { ThemeProvider, useTheme } from 'styled-components';

import { ButtonContent } from './components';
import StyledButton from './styles';
import { ButtonProps } from './types';
import { handleTheme } from 'utils/themeHelper';

function Button(props: ButtonProps): JSX.Element {
  const {
    link: Link,
    linkProps,
    label,
    category = 'primary',
    width,
    size = 'medium',
    disabled,
    onClick,
    icon,
    type = 'button',
    className,
    styles,
    isDanger,
    isActive,
    ...buttonProps
  } = props;
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  const as = Link
    ? ({ className: linkClassName, children }) => (
      <Link className={linkClassName} {...linkProps}>
        {children}
      </Link>
    )
    : 'button';

  return (
    <ThemeProvider theme={theme}>
      <StyledButton
        as={as as any}
        category={category}
        width={width}
        disabled={disabled}
        onClick={onClick}
        type={type}
        className={className}
        styles={styles}
        label={label}
        isDanger={isDanger}
        isActive={isActive}
        size={size}
        {...buttonProps}
      >
        <ButtonContent label={label} icon={icon} />
      </StyledButton>
    </ThemeProvider>
  );
}

export default Button;

import { HTMLAttributes, HTMLProps, MutableRefObject } from 'react';

import styled, { DefaultTheme } from 'styled-components';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  isActive: boolean;
  ref: MutableRefObject<HTMLButtonElement>;
  theme: DefaultTheme;
}

const StyledTab = styled.button<Props>`
  background-color: ${(props) => props.theme.colors!.white};
  border-radius: 0.3rem;
  border: 0.15rem solid transparent;
  color: ${(props) => (props.isActive ? props.theme.colors!.black : props.theme.colors!.greyMediumDark)};
  cursor: pointer;
  font: ${(props) => props.theme.fonts.bodyMSemi};
  padding: 1rem;
  position: relative;
  transition: ${(props) => props.theme.transition};

  &:hover {
    color: ${(props) => props.theme.colors!.black};
  }

  &:focus,
  &:focus-visible {
    outline: transparent;
  }

  &::after {
    background: ${(props) => (props.isActive ? props.theme.colors!.black : 'transparent')};
    bottom: 0;
    content: '';
    display: block;
    height: 0.125rem;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  @media only screen and (min-width: 48rem) {
    padding: 1rem 0;
  }
`;

export default StyledTab;

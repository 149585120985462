import { ReactNode } from 'react';

import styled, { css } from 'styled-components';
import { ChevronUp } from 'assets';
import { ChevronDown } from 'assets';
import { ArrowRightCircle } from 'assets';

interface Props {
  isOpen: boolean;
}
interface StyledSignContainerProps {
  customHeaderIcon?: Node | ReactNode;
  isInternalMovement: boolean;
}

export const StyledSideBarWrapper = styled.div<Props>`
  align-items: flex-start;
  background-color: ${(props) => props.theme.colors!.white};
  box-shadow: 0 0 2rem rgba(64, 60, 57, 0.1), inset 0 0 0.00625rem rgba(64, 60, 57, 0.4);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  margin: 0;
  min-height: 100vh;
  padding: 1.25rem;
  position: fixed;
  right: 0;
  top: 0;
  transform: ${(props) => (props.isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(100vw, 0, 0)')};
  transition: ${(props) => props.theme.transition};
  width: 100vw;

  @media only screen and (min-width: 48rem) {
    transform: ${(props) => (props.isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(25rem, 0, 0)')};
    width: 25rem;
  }
`;

export const StyledControlsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledControlsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 4.375rem;
`;

export const StyledControlsButton = styled.button`
  align-items: center;
  background: ${(props) => props.theme.colors!.white};
  border: none;
  cursor: pointer;
  display: flex;
  height: 1.625rem;
  justify-content: center;
  width: 1.625rem;
  padding: 0;
  margin: 0;

  path {
    fill: ${(props) => props.theme.colors!.greyDark};
  }

  &:disabled {
    cursor: not-allowed;

    svg {
      stroke: none;
    }

    path {
      fill: ${(props) => props.theme.colors!.greyLight};
    }
  }
`;

export const StyledControlCrossWrapper = styled.div`
  margin-left: auto;
`;

export const StyledControlCross = styled(StyledControlsButton)`
  svg {
    height: 0.9375rem;
    width: 0.9375rem;
  }
`;

export const StyledIconContainer = styled.div`
  margin: 0 auto;
`;

export const StyledSignContainer = styled.div<StyledSignContainerProps>`
  align-items: center;
  background-color: ${(props) => props.theme.colors!.greyLight};
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  height: 2rem;
  justify-content: center;
  width: 2rem;

  svg {
    transform: scale(0.55);

    ${(props) =>
    !props.customHeaderIcon &&
    props.isInternalMovement &&
    css`
        transform: scale(0.55) rotate(-45deg);
      `}
  }

  circle {
    fill: ${(props) => props.theme.colors!.greyLight};
  }

  ${(props) =>
    !props.customHeaderIcon &&
    css`
      path {
        fill: ${props.theme.colors!.black};
      }
    `}

  @media only screen and (min-width: 48rem) {
    height: 3.5rem;
    width: 3.5rem;

    svg {
      transform: scale(1);

      ${(props) =>
    !props.customHeaderIcon &&
    props.isInternalMovement &&
    css`
          transform: scale(1) rotate(-45deg);
        `}
    }
  }
`;

export const StyledSidebarChildrenWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const StyledArrowUp = styled(ChevronUp)`
  fill: ${(props) => props.theme.colors!.black};
`;

export const StyledArrowDown = styled(ChevronDown)`
  fill: ${(props) => props.theme.colors!.black};
`;

export const StyledArrowRight = styled(ArrowRightCircle)`
  fill: ${(props) => props.theme.colors!.black};
`;

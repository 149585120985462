import * as React from 'react';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';
import StyledSvg from './styles';

interface Props {
  className?: string;
  color?: string;
  width?: string;
  styles?: object;
}

function Logo(props: Props): JSX.Element {
  const { color, width = '14.25rem', className, styles } = props;
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  return (
    <ThemeProvider theme={theme}>
      <StyledSvg styles={styles} color={color} width={width} className={className} />
    </ThemeProvider>
  );
}

export default Logo;

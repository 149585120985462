import React, { ChangeEvent } from 'react';
import { format, isValid } from 'date-fns';

import { HandleTypes } from '../types';
import { FROM_DATE, TO_DATE, SINGLE_DATE } from '../constants'
import { validateInputDate } from '../utils';
import S from './styles';

const Handle = ({
  customHandler,
  hasExternalInputFields,
  selectedRange,
  handlerRef,
  isCalendarPopupOpen,
  placeholder,
  handleDateInputChange,
  isRange,
  popperRef,
  onHandlerClick,
  selectedDateInputValue,
  onDateInputFocus,
  error,
  inputError,
  inputValues
}: HandleTypes) => {

  const onClick = () => {
    onHandlerClick();
  }

  if (customHandler) {
    const CustomHandle = customHandler;

    return <CustomHandle />;
  }

  const onChange = (dateType: string) => (e: ChangeEvent<HTMLInputElement>, dateInputType: string) => {
    const isValidDate = validateInputDate(e.target.value, dateInputType);
    if (isValidDate) {
      handleDateInputChange(e, dateType, dateInputType);
    }
  }

  const startDateType = isRange ? FROM_DATE : SINGLE_DATE;

  if (hasExternalInputFields) {
    return (
      <>
        <S.HandlerInputContainer ref={popperRef} error={Boolean(inputError)}>
          <S.HandlerDateInput
            onClick={onClick}
            values={isRange ? inputValues.from : selectedDateInputValue}
            onChange={onChange(startDateType)}
            error={error[startDateType]}
            onDateInputFocus={() => onDateInputFocus(startDateType)}
          />
          {isRange && (
            <>
              <S.InputHandlerSeparatorContainer>
                &#8212;
              </S.InputHandlerSeparatorContainer>

              <S.HandlerDateInput
                onClick={onClick}
                values={inputValues.to}
                onChange={onChange(TO_DATE)}
                error={error[TO_DATE]}
                onDateInputFocus={() => onDateInputFocus(TO_DATE)}
              />
            </>
          )}
          <S.InputLabel>Date range</S.InputLabel>
        </S.HandlerInputContainer>
        {inputError && <S.ErrorMessage>{inputError}</S.ErrorMessage>}
      </>
    );
  }

  let handler = placeholder;

  if (selectedRange?.from && isValid(selectedRange?.from) && selectedRange?.to && isValid(selectedRange?.to)) {
    const { from, to } = selectedRange;
    const fromLabel = format(from, 'MMM d');
    const toLabel = format(to, 'MMM d');

    handler = `${fromLabel} - ${toLabel}`;
  }

  return (
    <span ref={popperRef}>
      <S.HandlerLabelContainer onClick={onClick} ref={handlerRef} >
        <S.CalendarIcon />
        <S.DateRangeWrapper>
          <span id='calendar_label_handle'>{handler}</span> <S.DateRangeChevronIcon $isRotated={isCalendarPopupOpen} />
        </S.DateRangeWrapper>
      </S.HandlerLabelContainer>
    </span>
  );
};

export default Handle;

import React, { HTMLProps, useState } from 'react';
import { StyledButton, StyledField, StyledLabel, StyledContent, StyledCopyIcon, StyledSuccessIcon } from './styles';
import { copyClipboardApi, copyFallback } from './helpers'
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';

interface Props extends HTMLProps<HTMLDivElement> {
  content: string;
  label: string;
  styles?: object;
  withCopying?: boolean;
  handleCopy?: (content: string) => void;
}

const COPY_TIMEOUT = 1000;

function ReadOnlyField(props: Props): JSX.Element {
  const [isCopyClicked, setIsCopyClicked] = useState(false);
  const { as, styles, className, content, label, withCopying, handleCopy, ...divProps } = props;
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  const onCopy = async () => {
    setIsCopyClicked(true);
    handleCopy?.(content);
    setTimeout(() => {
      setIsCopyClicked(false);
    }, COPY_TIMEOUT);
    if (navigator.permissions && navigator.clipboard) {
      await copyClipboardApi(content);
    }
    else {
      copyFallback(content);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledField {...divProps} styles={styles} as={as as any} className={className}>
        <StyledLabel>{label}</StyledLabel>
        <StyledContent>{content}</StyledContent>
        {withCopying && (
          <StyledButton onClick={onCopy} type='button' title='Click to copy'>
            {isCopyClicked ? <StyledSuccessIcon /> : <StyledCopyIcon />}
          </StyledButton>
        )}
      </StyledField>
    </ThemeProvider>
  );
}

export default ReadOnlyField;

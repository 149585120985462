import React, { Children, ReactNode } from 'react';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';

interface Props {
  children: ReactNode;
  className?: string;
}

function TabPanel(props: Props) {
  const { children, className } = props;

  if (!children) {
    return null;
  }

  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  return Children.map(children, (child: any, tabIndex: number) => (
    <ThemeProvider theme={theme}>
      <div
        aria-labelledby={`tab-label-${tabIndex + 1}`}
        id={`tab-panel-${tabIndex + 1}`}
        key={`tab-panel-${tabIndex + 1}`}
        role='tabpanel'
        className={className}
      >
        {child}
      </div>
    </ThemeProvider>
  ));
}

export default TabPanel;

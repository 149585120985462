
import styled from 'styled-components';

interface Props {
  isActive: boolean;
}

export const StyledAccordionContentWrapper = styled.div<Props>`
  visibility: ${(props) => props.isActive ? 'unset' : 'hidden'};
  align-items: flex-start;
  height: 100%;
  justify-content: flex-start;
  height: ${(props) => (props.isActive ? '100%' : '0')};
  opacity: ${(props) => (props.isActive ? '1' : '0')};
  overflow-y: ${(props) => (props.isActive ? 'auto' : 'hidden')};
  transition: all 0.4s ease;
  width: 100%;
`;

export const StyledAccordionPanelButton = styled.button<Props>`
  align-items: center;
  background: transparent;
  border: 0.0625rem solid transparent;
  border-bottom-color: ${(props) => (props.isActive ? 'transparent' : props.theme.colors.greyBright)};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font: ${(props) => props.theme.fonts.bodyM};
  justify-content: flex-start;
  outline: none;
  padding: 1rem 2rem 1rem;
  position: relative;
  width: 100%;
  color: ${props => props.theme.colors.black};

  &::before,
  &::after {
    background: ${(props) => props.theme.colors.blue};
    border-radius: 1.5rem;
    box-sizing: inherit;
    content: '';
    display: block;
    height: 0.13rem;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate(0.25rem, 0);
    transition: 0.1s opacity ease;
    width: 1rem;
  }

  &::after {
    opacity: ${(props) => (props.isActive ? 0 : 1)};
    transform: rotate(90deg) translate(0, -0.25rem);
  }
`;

import React, { FunctionComponent, ReactElement } from 'react';
import { ThemeProvider, useTheme } from 'styled-components';
import { handleTheme } from 'utils/themeHelper';
import {
  CurrencyOptionWrapper,
  CurrencyOptionOptionIcon,
  CurrencyOptionColumn,
  CurrencyOptionCurrencyName,
  CurrencyOptionBottomLineWrapper,
  CurrencyOptionAccount,
  CurrencyOptionAmount,
} from './styles';

interface Props {
  accountNumber?: string;
  amount?: string;
  className?: string;
  currencyShort?: string;
  highlightDetails?: boolean;
  icon?: FunctionComponent;
  name?: string;
  sortCode?: string;
  paymentRail?: string;
}

function CurrencyOption(props: Props): ReactElement {
  const {
    icon: Icon,
    name,
    amount,
    sortCode,
    accountNumber,
    currencyShort,
    highlightDetails,
    className,
    paymentRail
  } = props;
  const parentTheme = useTheme();
  const theme = handleTheme(parentTheme);

  return (
    <ThemeProvider theme={theme}>
      <CurrencyOptionWrapper className={className}>
        {Icon && (
          <CurrencyOptionOptionIcon data-testid='currency-option-icon-wrapper'>
            <Icon data-testid='currency-option-icon' />
          </CurrencyOptionOptionIcon>
        )}
        <CurrencyOptionColumn data-testid='currency-option-column'>
          {name && <CurrencyOptionCurrencyName data-testid='currency-option-name'>{name}</CurrencyOptionCurrencyName>}
          <CurrencyOptionBottomLineWrapper
            data-testid='currency-option-bottom-line-wrapper'
            highlightDetails={highlightDetails}
          >
            {paymentRail && <span data-testid='currency-option-payment-rail'>{paymentRail} • </span>}
            {currencyShort && <span data-testid='currency-option-currency-short'>{currencyShort}</span>}
            {amount && (
              <CurrencyOptionAmount data-testid='currency-option-amount' leftOffset={!!currencyShort}>
                {amount}
              </CurrencyOptionAmount>
            )}
            {accountNumber && (
              <CurrencyOptionAccount data-testid='currency-option-account'>• {accountNumber} •</CurrencyOptionAccount>
            )}
            {sortCode && <span data-testid='currency-option-sortcode'>{sortCode}</span>}
          </CurrencyOptionBottomLineWrapper>
        </CurrencyOptionColumn>
      </CurrencyOptionWrapper>
    </ThemeProvider>
  );
}

export default CurrencyOption;

import * as React from 'react';
import styled, { DefaultTheme } from 'styled-components';

interface Props {
  theme: DefaultTheme;
  fontSize: string;
  styles?: object;
  children: any;
}

const StyledTitle = styled.h1<Props>`
  font: ${(props) => props.theme.fonts.h1Bold};
  font-size: ${(props) => props.fontSize};
  margin: 0;
  line-height: calc(${(props) => props.fontSize}*1.2);
  color: ${(props) => props.theme.colors!.black};
  ${(props) => ({ ...props.styles })}
`;

export default StyledTitle;
